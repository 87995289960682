.launching-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 48px ;
    box-sizing: border-box;
    gap: 48px;
}

.launching-head {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    text-align: left;
    width: 100%;
    color: #001325;
    font-family: Syne;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    /* 112.5% */
    letter-spacing: -1.2px;

}

.launching-items {
    display: flex;
    gap: 40px;
    /* flex-wrap: wrap; */
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
}

.launching-content {
    width: 344px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 476px;
    text-align: left;
    justify-content: left;
    border-radius: 20px;
    border: 1px solid  #610BEE;
    background:  #FFF;
    padding: 16px;
}

.launching-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* border-radius: 9999px;
    border: 1px solid rgba(31, 114, 249, 0.50); */
}

.launching-img img {
    width: 64px;
    height: 64px;
}

.launching-desc {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: left;
}

.launching-desc span {
    color: #001325;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.192px;
}

.launching-desc li {
    color: rgba(0, 19, 37, 1);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
}


@media screen and (max-width:1000px) {
    .launching-page {
        padding: 48px 250px;
    }

    .launching-head {
        font-size: 32px;
    }

    .launching-items {
        flex-wrap: wrap;
        /* flex-direction: column; */
        /* flex-wrap: nowrap; */
        gap: 32px;
        /* height: auto; */
    }
    .launching-content{
        height: auto;
    }
}

@media screen and (max-width:800px) {
    .launching-page {
        padding: 50px;
    }
}

@media screen and (max-width:500px) {
    .launching-page {
        padding: 20px 15px;
    }

    .launching-content {
        width: 100%;
        box-sizing: border-box;
    }

    .launching-head {
        color: var(--Text-Display, #001325);
        font-family: Syne;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        /* 75% */
        letter-spacing: -0.384px;
    }
}