
.addcollab-page {
  display: flex;
  position: absolute;
  justify-content: center;
  background-color: rgba(0, 19, 37, 0.5);
  z-index: 6;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  height: auto;
  top: 0px;
  left: 0px;
  
}
.addcollab-container {
  display: flex;
  flex-direction: column;
  padding: 3.6vh 1.7vw;
  width: 38vw;
  max-height: 100vh;
  height: auto;
  
  background: white;
  border-radius: 12px;
  border: 1px solid #edf2f7;
  box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
  position: absolute;
  top: 20%;
  left: 30%;

}
.addcollab-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.6vh;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
}
.addcollab-head span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.addcollab-invite {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 3.6vh;
}
.addcollab-invite span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.inp-btn-collab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.inp-btn-collab input {
  padding: 1.5vh 1.1vw;
  width: 29.5vw;
  outline: none;
  border: 2px solid #edf2f7;
  border-radius: 4px;
  height: 6vh;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.inp-btn-collab button {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  letter-spacing: -0.14px;
  height: 6vh;
  padding: 1.5vh 1.4vw;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%
    ),
    #610bee;
}
.addcollab-collaboarters {
  display: flex;
  flex-direction: column;
  gap: 2.4vh;
  width: 100%;
}
.collabs {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}
.collab-search {
  display: flex;
  border-radius: 4px;
  border: 1px solid #edf2f7;
  background: white;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  height: 6vh;
  gap: 8px;
  /* width: 37.3vw; */
  width: 100%;
}
.collab-search input {
  height: 100%;
  width: 90%;
  border: none;
  outline: none;
  box-sizing: border-box;
}
.collab-members-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collab-members {
  max-height: 30vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.collab-members img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.collab-span {
  display: flex;
  gap: 10px;
  align-items: center;
}
.collab-members-list:hover {
  background: #f6f6f6;
  cursor: pointer;
}
.collab-members button {
  border: none;
  background: none;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: black;
  border-radius: 5px;
}

.collab-members-conatiner {
  display: flex;
  flex-direction: column;
  max-height: 27.7vh;
  overflow-y: scroll;
  padding-right: 75px;
}

.collab-list-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 18px;
}

.collab-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  max-height: 25vh;
  overflow-y: auto;
}

.collab-list img{
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.collab-list-menu{
  box-sizing: border-box;
  display: flex;
  padding-right: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.collab-list-menu:hover{
  background: #f6f6f6;
}
.collab-list-menu .remove-btn-addcolab {
  display: none;
}
.collab-list-menu:hover .remove-btn-addcolab {
  display: block;
}

/* searching comp */
.collab-mem-list {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 6px 16px;
  height: 6vh;
}
.collab-mem-list input{
  width: 1.1vw;
  height: 2.4vh;

}




.collab-mem-list img {
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.collab-members-btns {
  display: flex;
  padding: 12px 16px;
  background: white;
  justify-content: space-between;
  align-items: center;
  height: 8.5vh;
  bottom: 0;

  
}
.collab-btns{
  display: flex;
  gap: 8px;
  

}
.collab-btns button{
  border-radius: 4px;
  border: none;
  height: 4.8vh;
  padding: 8px 12px;
}
/* .collab-btns button:hover{
 cursor: pointer;
} */
.btn-sv{
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #610BEE;
  color: white;
  cursor: pointer;
}
.save-button-active {
  background: #610BEE;
  cursor: pointer; /* Replace with your desired background color */
  /* Add any other styles for the active state */
}

.btn-cl{
  color: #610BEE;
  background: white;
  border: 1px solid white;
}
.btn-cl:hover{
  border: 1px solid #610BEE;
}
.entire-division input[type=checkbox] {
  position: relative;
	border: 3px solid #e0e6ec;
	border-radius: 4px;
	background: none;
	cursor: pointer;
	line-height: 0;
	margin: 0 .6em 0 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 16px;
	width: 16px;
	-webkit-appearance: none;
  opacity: .5;
}

.entire-division input[type=checkbox]:hover {
  opacity: 1;
}

.entire-division input[type=checkbox]:checked {
  border: 3px solid #ff7a7a;
  background-color:  #ff7a7a;
  opacity: 1;
}

.entire-division input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
.entire-division{
  /* position: relative; */
  /* max-height: 42.3vh; */
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid #610BEE;
  border-radius: 5px;
  margin-bottom: 2px;
}
.remove-btn-addcolab{
  border: none;
  background: none;
  cursor: pointer;
}