.screen {
  position: absolute;
  top: 150px;
  left: 50%;
  z-index: 2;
  display: flex;
  align-items: center;
  background: inherit;
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
.screen1{
  overflow-x: hidden;
  position: absolute;
  top: 30%;
  left: 80%;
  z-index: 5;
  display: flex;
  align-items: center;
  background: inherit;
  width: 100%;
  height: 50vh;
} 
.excell-loading{
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  position: absolute;
  top: 60%;
  /* left: 35%; */
  z-index: 5;
  display: flex;
  align-items: center;
  background: inherit;
  width: 60%;
  height: 50vh;
}
/* .main-loader-div {
  width: 300px;
  position: relative;
  box-sizing: border-box;
} */

/* .loader {
  width: 100%;
  height: 15px;
  text-align: center;
  overflow: hidden;
} */

.dot {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 0 2px;
  z-index: 2;
  display: inline-block;
}

.dot:first-child:before {
  animation-delay: 0ms;
}

.dot:first-child:after {
  animation-delay: 0ms;
}

.dot:last-child:before {
  animation-delay: 200ms;
}

.dot:last-child:after {
  animation-delay: 200ms;
}

.dot:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 15px;
  background-color: blue;
  animation-name: dotHover;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(.82, 0, .26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
  background: #610BEE;
  border-radius: 100%;
}

.dot:after {
  content: "";
  position: absolute;
  z-index: -1;
  background: black;
  box-shadow: 0px 0px 1px black;
  opacity: .20;
  width: 100%;
  height: 3px;
  left: 0;
  bottom: -2px;
  border-radius: 100%;
  animation-name: dotShadow;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(.82, 0, .26, 1);
  animation-iteration-count: infinite;
  animation-delay: 100ms;
}

@keyframes dotShadow {
  0% {
    transform: scaleX(1);
  }

  50% {
    opacity: 0;
    transform: scaleX(.6);
  }

  100% {
    transform: scaleX(1);
  }
}

@keyframes dotHover {
  0% {
    top: 0px;
  }

  50% {
    top: -50px;
    transform: scale(1.1);
  }

  100% {
    top: 0;
  }
}




.loader {
  width: 60px;
}

.loader-wheel {
  animation: spin 1s infinite linear;
  border: 2px solid rgba(30, 30, 30, 0.5);
  border-left: 4px solid #fff;
  border-radius: 50%;
  height: 50px;
  margin-bottom: 10px;
  width: 50px;
}

.loader-text {
  color: #fff;
  font-family: arial, sans-serif;
}

.loader-text:after {
  content: 'Loading';
  animation: load 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    content: 'Loading';
  }
  33% {
    content: 'Loading.';
  }
  67% {
    content: 'Loading..';
  }
  100% {
    content: 'Loading...';
  }
}




/* file loading  */
.file-loading {
  
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  width: fit-content;
  color: black;
  margin: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.file-loading span {
  position: absolute;
  height: 10px;
  width: 100%;
  top: 50px;
  left: 30%;
  overflow: hidden;
}
.file-loading span > i {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  -webkit-animation: wait 4s infinite;
  -moz-animation: wait 4s infinite;
  -o-animation: wait 4s infinite;
  animation: wait 4s infinite;
}
.file-loading span > i:nth-of-type(1) {
  left: -28px;
  background: #610BEE;
}
.file-loading span > i:nth-of-type(2) {
  left: -21px;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #610BEE;
}

@-webkit-keyframes wait {
  0%   { left: -7px  }
  30%  { left: 52px  }
  60%  { left: 22px  }
  100% { left: 100px }
}
@-moz-keyframes wait {
  0%   { left: -7px  }
  30%  { left: 52px  }
  60%  { left: 22px  }
  100% { left: 100px }
}
@-o-keyframes wait {
  0%   { left: -7px  }
  30%  { left: 52px  }
  60%  { left: 22px  }
  100% { left: 100px }
}
@keyframes wait {
  0%   { left: -7px  }
  30%  { left: 52px  }
  60%  { left: 22px  }
  100% { left: 100px }
}





/* button loader */
.btn-ring {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: ring 1.2s linear infinite;
}
@keyframes ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}