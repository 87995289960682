.workspace-detail-tab {
  display: grid;
  gap: 24px;
  width: 100%;
  height: 50vh;
}

.workspace-file-list-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    /* width: 100%; */
    height: 80vh;
    overflow-y: scroll;
}