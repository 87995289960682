.ticket-view-Page {
  width: 100%;
  height: 90vh;
  overflow: auto;
}

.ticket-view-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 40px 0px 28px;
}

.ticket-view-right {
  display: flex;
  gap: 4px;
  align-items: center;
}

.ticket-view-left {
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}

.clonedelete {
  position: absolute;
  bottom: -80px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  border-radius: 8px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
  width: 7.8vw;
  height: auto;
  z-index: 2;
}

.clonedelete button {
  height: 4.5vh;
  border: none;
  background: white;
  cursor: pointer;
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.clonedelete button:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #5B89FF;
}


.ticket-view-bottm-right {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 40px;
  padding-top: 20px;
}

.ticket-view-bottom {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}

.ticket-view-bottom-head {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-start;
}

.ticket-view-bottom-num {
  color: #001325;
  /* Regular/14 */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.custom-textarea {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: none;
  box-sizing: border-box;
  /* border: 1px dashed #c7cacd; */
}

.custom-textarea-title {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #c7cacd;
}
.custom-textarea img,
.custom-textarea-title img {
  cursor: pointer;
  display: none;
}

.custom-textarea:hover img,
.custom-textarea-title:hover img {
  display: flex;
}

.ticket-view-bottom-desc {
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.288px;
  border: none;
  outline: none;
  width: 100%;
  word-wrap: break-word;
}

.custom-textarea-title {
  width: 43vw;
  border-radius: 4px;
  resize: none;
  display: flex;
  align-items: center;
  gap: 10px;
  /* border-bottom: 2px solid #edf2f7; */
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.288px;
  padding: 10px 10px 10px 10px;
  outline: none;
}

.custom-textarea {
  width: 43vw;
  /* height: 6.7vh; */
  font-size: 15px;
  border-radius: 4px;
  resize: none;
  /*  */
  word-wrap: break-word;
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  /* border: none; */
  padding: 10px 10px 10px 10px;
  outline: none;
  /* outline: none; */
}

.ticket-view-bottm-left {
  border-radius: 8px;
  border: 1px solid #edf2f7;
  background: #fff;
  margin-top: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  height: 75vh;
  overflow-y: auto;
  width: 30%;
}

.owner-image {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  /* overflow: hidden; */

}

.owner-image-span {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  /* margin-right: 6px; */
}

.owner-image-changes {
  margin-right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  width: 28px;
  height: 28px;
}

.parag-changes {
  width: 9vw;

}

.parag-changes-watchers {
  width: 8.5vw;
}

.owner-image-span,
.owner-change-image-span {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.owner-change-image-span {
  width: 26px;
  height: 26px;
}

.owner-select {
  display: flex;
  align-items: center;
  gap: 16px;
}

.owner-select span {
  width: 8.2vw;
}

.priority-icon {
  width: 40px;
  height: 18px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.security-icon {
  height: 18px;
  display: inline-block;
  border-radius: 12px;
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  padding: 0px 5px;
}

.owner-date {
  display: flex;
  align-items: center;
  gap: 16px;
}

.owner-date span {
  width: 9vw;
}

.owner-date input {
  padding: 10px 16px;
  width: 18vw;
  border-radius: 4px;
  border: 1px solid #edf2f7;
  outline: none;
  box-sizing: border-box;
}

.ticket-view-ec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.ticket-view-span {
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  max-width: 95%;
  /* text-wrap: wrap; */
  word-break: break-all;
  padding-right: 10px;
  /* word-break: break-all; */
  /* 133.333% */
}
.ticket-view-span-title{
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  max-width: 95%;
  /* text-wrap: wrap; */
  word-break: break-all;
  padding-right: 10px;
}
.ticket-view-status-div {
  width: 92%;
  height: 65px;
  border-radius: 4px;
  background: #F6F6F6;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 15px;
  /* justify-content: space-between; */
}

.ticket-view-status-bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 285px;
  gap: 10px;
}

.ticket-view-status-name {
  color: #080808;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  /* 133.333% */
}

.ticket-view-status-btn-approve,
.ticket-view-status-btn-reject {
  border-radius: 4px;
  background: #610BEE;
  padding: 10px 14px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
  border: none;
}

.ticket-view-status-btn-reject {
  color: #610BEE;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
}

.status-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #610BEE;
}

.status-line {
  height: 3px;
  width: 105px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #610BEE;
}

.security-select {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #001325;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 360px;
}

.security-select-date {
  width: 15.8vw;
  height: 5.6vh;
  box-sizing: border-box;
  border: 1px solid #EDF2F7;
  outline: 1px solid #EDF2F7;
}

.security-select-span {
  width: 126px;
}

.form-link {
  color: blue;

}

.form-link:hover {
  text-decoration: underline;
}

.customdetails {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.custom-fields-details {
  display: flex;
  gap: 16px;
  align-items: center;
}

.customdetails span,
label {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.custom-details-div {
  display: flex;
  align-items: center;
}

.custom-label-span {
  width: 142px;

}

.custom-label-innerdiv {
  width: 242px;


}

.custom-label-innerdiv a {
  width: 230px;
  word-break: break-all;

}

.custom-label-innerdiv img {
  visibility: hidden;
}

.custom-label-innerdiv:hover img {
  visibility: visible;
  cursor: pointer;
}

.custom-date-range {
  display: flex;
  flex-direction: column;
}

.customdetails input,.dates_ranges-input {
  width: 15.8vw;
  height: 5.6vh;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #EDF2F7;
  outline: 1px solid #EDF2F7;
  color: #001325;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.customdetails textarea {
  width: 15.8vw;
}

.customdetails textarea {
  width: 15.8vw;
}

.name-photo-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
}

.comments-textarea {
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #edf2f7;
  background: #f3f2f2;
  width: 85%;
  height: 10.3vh;
  resize: none;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  outline: none;
}

.single-select-tag,
.security-select-tag {
  width: 15.8vw;
  box-sizing: border-box;
}

.comments-bottom-cont {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-top: 12px;
  gap: 12px;
}

.get-comments-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  /* height: 300px; */
}

.comment-details-cont {
  display: flex;
  width: 41vw;
  max-height: 150px;
  gap: 8px;
  padding: 10px;
  cursor: pointer;
  overflow-y: auto;
}

.comment-details-cont:hover {
  background: #F5F5F5;
}

.text-area-type {
  color: #4C5E68;
  /* Regular/14 */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.comment-details-cont:hover .text-area-type {
  background: #F5F5F5;
}

.commented-member-image img {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}

.commented-details {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.commented-member-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.commenter-name {
  color: #001325;
  /* Medium/14 */

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.commented-time {
  color: #4C5E68;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}


/* change logs container */


.changelog-page,
.change-details-page,
.delete-ticket-page {
  position: absolute;
  z-index: 6;
  background-color: rgba(0, 19, 37, 0.5);
  width: 100%;
  min-height: 115vh;
  height: auto;
  top: 0px;
}

.changelog-container {
  background: white;
  width: 27.6vw;
  min-height: 115vh;
  height: auto;
  position: absolute;
  right: 0px;
  padding: 24px;
  box-sizing: border-box;
  z-index: 8;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}



.changelog-container-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.changelog-container-head span {
  color: #001325;

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;

}

.changelogs-details {
  padding-left: 12px;
}

.changelogs-status {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.changelogs-day {
  margin-top: 3.4vh;
  color: #001325;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.changeLong-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #c4c9cc;
}

.changelogs-heads {
  display: flex;
  align-items: center;
  gap: 8px;

}

.changelogs-fieldname {
  color: #001325;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.changelogs-time {
  color: #001325;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.changelogs-prev {
  display: flex;
  align-items: center;
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 15px;
}

.changelogs-by {
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding-left: 15px;
}

.tckt-tmpt-btns {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.tckt-tmpt-btns button {
  cursor: pointer;
  border-radius: 5px;
  padding: 10px 20px;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.tckt-tmpt-btns-save {
  background: #610BEE;
  border: none;
  color: white;
}

.tckt-tmpt-btns-save1 {
  background: #610BEE;
  border: none;
  color: white;
}

.tckt-tmpt-btns-cnl {
  background: white;
  border: 1.5px solid #7A5AF8;
  color: #7A5AF8;

}


.user-info {
  display: flex;
  align-items: center;
}

.defaultDetails {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.extension-type-div {
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;

}

.extension-type-div iframe {
  transform: scale(1.75);
  overflow: hidden;
  background: white;
  width: 130%;
  height: 12vh;
  padding: 0px;
  margin: 0px;
}

.extension-type-div iframe::-webkit-scrollbar {
  display: none;
}

/* change details popup */
.change-details-cont {
  width: 27.6vw;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  position: absolute;
  height: auto;
  position: absolute;
  top: 27%;
  left: 35%;
  display: flex;
  flex-direction: column;
  z-index: 8;
  padding: 24px;
  gap: 24px;
}

.change-details-title {
  color: #001325;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.change-details-description {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 12px;
}

.change-details-div {
  display: flex;
  align-items: center;

}

.arrow-watchers {
  margin-left: 20px;
  margin-right: 20px;
}

.change-details-div-dec {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  gap: 16px;

}

.change-details-div-dec img {
  transform: rotate(90deg);
}

.change-details-fieldName {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.14px;
  margin-bottom: 8px;
}

.change-details-previous,
.change-details-previous-dec {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: line-through;
  margin-right: 18px;
}

.change-details-newData,
.change-details-newData-dec {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

}

.change-details-buttons {
  display: flex;
  gap: 8px;
}

.change-details-change {
  border-radius: 5px;
  background: #6448CE;
  padding: 10px 20px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  border: none;
  cursor: pointer;
}

.change-details-cancel {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1.5px solid #7A5AF8;
  color: #7A5AF8;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  cursor: pointer;
}

.new-text-div,
.old-text-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25vw;
}

.old-text-div {
  text-decoration: line-through;
}

/* .link-input {
  border: none;
  color: blue;
  text-decoration: underline; 
  cursor: pointer; 
  background-color: transparent; 
} */


.new-text-div span,
.old-text-div span {
  white-space: normal;
  display: block;
  /* Display the span as a block to make it wrap to the next line */
  width: 25vw;
  word-break: break-all;
}

/* delete page */

.delete-ticket-cont {
  width: 27.6vw;
  height: 25.6vh;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  position: absolute;
  height: auto;
  position: absolute;
  top: 35%;
  left: 35%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 8;
  padding: 24px;
}

.uploaded-files-view {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

}

.uploaded-file-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
  position: relative;
  margin-bottom: 5px;
}

.file-details-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 8vw;
  gap: 12px;
  padding: 3px;
  height: 12vh;
  border: 1px solid #c7cacd;
  border-radius: 4px;
  position: relative;
}

.file-image-preview {
  width: 100%;
  height: 12vh;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.png-img-div {
  width: 100%;
  height: 12vh;
}

.file-details-view:hover {
  cursor: pointer;
}

.file-details-view-hover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 8vw;
  padding: 3px;
  height: 12vh;
  border: 1px solid #c7cacd;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  display: none;
}

.file-details-view-hover:hover {
  display: flex;
}

.upld-file-details-view {
  width: 10vw;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  margin-bottom: 5px;
}

.file-details-view:hover .file-details-view-hover {
  display: flex;
}

.file-details-view:hover .file-view-name {
  display: none;
}

.file-view-name-view {
  /* width: 15vw; */
  color: white;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* padding-left: 10px; */

}

.file-box-link {
  display: flex;
  width: 8vw;
  height: 8vh;

}

.file-view-name {
  color: black;
  padding-left: 12px;
  /* margin: 12px; */
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;

}

.file-delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  border-radius: 8px;
  width: 30px;
  height: 30px;
  color: white;

}

.file-delete:hover {

  background-color: rgba(33, 34, 34, 0.5);


}


.engineering-details-change {
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 12px;

}

.engineering-details-change img {
  display: none;
  cursor: pointer;
}

.engineering-details-change:hover img {
  display: flex;
}

.engineering-editing-text {
  width: 100%;
  border: none;
  outline: none;
  background: inherit;
}

.engineering-editing-text .editing-engineering {
  display: flex;
}

.engineering-editing-text:hover .editing-engineering {
  display: none;
}

.part-numbers-views {
  display: flex;
  width: 100%;
  flex-direction: column;
  border-bottom: 1px solid #E6E4F0;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 75px;
  /* background: grey; */

}

.part-number-parts-divs-ec {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 15px;
  border-bottom: 1px solid #E6E4F0;
  padding-bottom: 15px;
  box-sizing: border-box;
  height: 50vh;
  overflow-y: auto;
}

.part-numbers-views-ec {
  display: flex;
  width: 100%;
  align-items: center;

  border-bottom: 1px solid #E6E4F0;
  /* padding-bottom: 20px; */
  box-sizing: border-box;
  height: 60px;
  padding-left: 10px;
  /* padding-top: 15px; */
}

.part-numbers-views:hover,
.part-numbers-views-ec:hover {
  cursor: pointer;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
}

.part-numbers-views-details {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 12px;
  align-items: flex-start;

  justify-content: space-between;
  height: 76px;
}

.part-numbers-views-details-ec {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 5px;
  /* height: 50vh; */
}

.part-numbers-views-checkbox {
  position: relative;
  width: 25px;
  height: 25px;
  /* margin-right: 15px; */
}

/* CSS for checkbox */
.part-numbers-views-checkbox input[type="checkbox"] {
  appearance: none;
  /* Remove default appearance */
  -webkit-appearance: none;
  /* Remove default appearance for Safari */
  -moz-appearance: none;
  /* Remove default appearance for Firefox */
  width: 24px;
  /* Set width */
  height: 24px;
  /* Set height */
  border: 2px solid #000;
  /* Set border color */
  border-radius: 4px;
  /* Set border radius */
  background-color: #fff;
  /* Set background color */
  cursor: pointer;
  /* Change cursor on hover */
}

/* CSS for checkbox tick mark */
.part-numbers-views-checkbox input[type="checkbox"]:checked::after {
  content: '\2714';
  /* Unicode character for checkmark */
  font-size: 16px;
  /* Set font size */
  color: #fff;
  /* Set color of the checkmark */
  position: absolute;
  border-radius: 4px;
  background: #000;
  text-align: center;
  width: 24px;
  /* Set width */
  height: 24px;
  /* Position the checkmark */
  top: 15px;
  /* Align vertically */
  left: 15px;
  /* Align horizontally */
  transform: translate(-50%, -50%);
}

/* Hide default checkbox */
.part-numbers-views-checkbox input[type="checkbox"]::-webkit-inner-spin-button,
.part-numbers-views-checkbox input[type="checkbox"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* Remove default appearance for webkit */
  margin: 0;
  /* Remove default margin */
}

.part-numbers-views-checkbox input[type="checkbox"]::-ms-check {
  display: none;
  /* Hide default check for IE10+ */
}

.part-numbers-views-details input[type='number'] {
  width: 80px;
  height: 40px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.part-numbers-views-content {
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.part-number-view-title {
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.part-number-view-number {
  color: #001325;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.192px;
  width: 100%;
  
}

.part-view-delete-parts {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  border-radius: 4px;
  background: #610BEE;
  border: none;
}

.part-number-parts-divs {
  width: 100%;
  height: 55vh;
  overflow-y: auto;
  box-sizing: border-box;
  overflow-x: hidden;

}

.part-numbers-views-content-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
  /* align-items: center; */
  /* justify-content: center; */
}

.part-options-child-div-1 {
  width: 100%;
  height: 50vh;
  overflow-y: auto;

}

/* .part-options-child-1{
  width: 90%;
} */
/* background: '#white', borderRadius: '4px', padding: '12px', display: 'flex', flexDirection: 'column', width: '92%'  */
.eng-detail-cont {
  background: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  width: 92%;
  border-radius: 4px;
  border: 1px solid #EDF2F7;
}

.part-number-parts-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  width: 500px;
}

.loader-parts {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}