.ticket-assign-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px 40px;
  margin-top: 11.3vh;
  width: 100%;
  transition: width 0.3s ease;
}
.ticket-assign-page-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #001325;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.48px;
}
.ticket-assign-page-heading button{
    display: flex;
    padding: 10px 20px;
    align-items: center;
    gap: 12px;
    /* width: 12vw; */
    /* height: 6.1vh; */
    background-color: #610bee;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}
.ticket-assign-page-filters {
  display: flex;
  align-items: center;
  gap: 12px;
}
.ticket-assign-page-filters-search {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  box-sizing: border-box;
  width: 20.4vw;
  height: 45px;
  border-radius: 30px;
  border: 1px solid #edf2f7;
  background: #fff;
}
.ticket-assign-page-filters-search input {
  width: 100%;
  outline: none;
  border: none;
}
.ticket-assign-table {
  width: 100%;
}
table {
  border-collapse: collapse;
}
.ticket-assign-table-data {
  width: 100%;
  text-align: left;
}
.ticket-assign-table-data th,
td {
  padding: 18px 12px;
}
.ticket-assign-table-data th {
  background: #f6f6f6;
  color: var(--accent-2-text-heading, #001325);
  /* Regualr/16 */
  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.first-header {
  width: 360px;
}
.second-header,
.third-header,
.fourth-header {
  width: 246.6px;
}
.ticket-assign-table-data tr:hover {
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #5b89ff;
}
.modify-item {
  display: flex;
  justify-content: space-between;
}
.modify-icons {
  display: flex;
  gap: 12px;
  visibility: hidden;
}
tr:hover .modify-icons {
  visibility: visible;
}
.table-modified {
  display: flex;
  gap: 10px;
  align-items: center;
}
.table-modified-pic {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}
.ticket-assign-table-data td[title]::before {
  content: attr(title);
  position: absolute;
  top: -30px; /* Adjust the distance from the content */
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  background-color: #000;
  color: #fff;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}

.ticket-assign-table-data td:hover[title]::before {
  opacity: 1;
}