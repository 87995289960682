.part-num-bottom-box {
    cursor: grab;
    height: 100%;
    /* width: 23px; */
    padding: 8px 6px;
    border-radius: 4px 0px 0px 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #5B89FF;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    box-sizing: border-box;
  }
  .template-drag-box-right,
.template-drag-box-left {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.dots {
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    background: black;
}