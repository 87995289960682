.compliance-page {
  display: flex;
  width: 100%;
  /* height: 587px; */
  padding: 102px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.compliance-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.compliance-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.compliance-head {
  color: #001325;
  text-align: center;
  font-family: Syne;
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 62.5px;
  /* 125% */
}

.compliance-desc {
  color: #001325;
  padding: 0px 5.4vw;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.compliance-imgs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 16px;

}

.compliance-imgs-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 14px;
}

.compliance-imgs-2 {
  width: 195px;
  height: 125px;
}

.compliance-imgs-3 {
  width: 140px;
  height: 125px;
}

.compliance-imgs-4 {
  width: 230px;
  height: 125px;
}

.compliance-imgs-5 {
  width: 116px;
  height: 125px;
}

.compliance-imgs-1 span {
  color: rgba(0, 19, 37, 1);
  text-align: center;
  font-family: Inter;
  font-size: 16.734px;
  font-style: normal;
  font-weight: 400;
  line-height: 25.5px;
  /* 152.381% */
}

@media screen and (max-width:600px) {
  .compliance-page {

    /* height: 587px; */
    padding: 20px;
  }

  /* .compliance-imgs{
    flex-direction: column;
  } */
  .compliance-head {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
  }

  .compliance-desc {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  /* font-size: 16.734px;
font-style: normal;
font-weight: 400;
line-height: 25.5px; */
  .compliance-imgs-1 span {
    font-size: 16.734px;
    font-style: normal;
    font-weight: 400;
    line-height: 25.5px;
  }

  
.compliance-imgs-2 {
  width: 250px;
  height: 150px;
}

.compliance-imgs-3 {
  width: 161px;
  height: 150px;
}

.compliance-imgs-4 {
  width: 275px;
  height: 150px;
}

.compliance-imgs-5 {
  width: 150px;
  height: 145px;
}
}