.supplier-page-view{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 90vh;
    overflow: auto;
    margin-top: 80px;
}
.supplier-page-top-bar{
    display: flex;
    align-items: center;
    padding: 8px 16px;
}
.supplier-page-bottom-page{
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
   
}
.supply-textarea{
    width: 288px;
    border: 1px solid #EDF2F7;
    border-radius: 4px;
    outline: none;
}
.supplier-page-default-fields{
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns with equal width */
    gap: 24px;
    width: 100%;
    /* height: 50vh; */
    
}
.supplier-page-custom-fields{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 30%;
    height: 50vh;
    border-radius: 8px;
    border: 1px solid #EDF2F7;
    background: #FFF;
    padding:24px;
}
.address-field{
    display: flex;
    align-items: center;
    gap: 5px;
}
.address-field-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
}