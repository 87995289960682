


.login-page {
  width: 100%;
  height: 100vh;
  display: flex;
}

.left-con {
  font-family: "Syne";
  width: 50%;
  height: 100vh;
  overflow: hidden;
  background: #610bee;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/bg-login.webp), url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/bg-voilet.webp);
  background-position: center, center;
  background-repeat: no-repeat, no-repeat;
  background-size: 100% 100%, 100% 100%;
  mix-blend-mode: color-burn;
  opacity: 1;
}

.headers {
  padding: 8vh 4.6vw 0.7vh 4.6vw;
  display: flex;
  flex-direction: column;
}

.head1 {
  font-style: normal;
  font-weight: 700;
  /* font-size: clamp(6px, 1.3vw, 16px); */
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5vh;
  color: #ffffff;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.head2 {
  font-style: normal;
  font-weight: 700;
  font-size: clamp(22px, 2.7vw, 40px);
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 50px;
  margin: 0;
}

.text {
  color: rgba(255, 207, 1, 1);
}

.triangles {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.left-img {
  width: 100%;
  height: auto;
}

.right-img {
  display: flex;
  width: 100%;
  position: absolute;
  right: 0;
  height: auto;
  z-index: -1;
}

.right-con {
  display: flex;
  justify-content: flex-start;
  padding-top: 20vh;
  /* align-items: center; */
  height: auto;
  width: 50%;
  font-family: "Inter";
  /* padding-top: 4.6vh; */
  padding-left: 9.5vw;
}


.right {
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: flex-start;
  width: 80%;

}


.reg-logo {
  width: 13vw;
  height: 5vh;
}


.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}


.account {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: clamp(16px, 2.2vw, 32px);
  letter-spacing: -0.015em;
}


.sign-btn {
  width: 100%;
  height: 50px;
  background: #f8f9fa;
  border-radius: 10px;
  border: none;
  display: flex;
  gap: 25px;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
}


.sign-btn:hover {
  cursor: pointer;
  color: #ffffff;
  background: #610bee;
}


.email-inp {
  width: 100%;
  border: none;
  outline: #7A5AF8;
  height: auto;
  border-radius: 5px;
}


.input-container,
.password-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid #9e9e9e;
  border-radius: 5px;
}


.password-container input {
  font-size: 16px;
  width: 100%;
  border: none;
  outline: none;
  height: auto;
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;
}


.password-container button {
  background: none;
  border: none;
}


.check-box {
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.check-box-para {
  color:  #425466;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; 
}
.check-box-para a {
 
  color: #610BEE;
  cursor: pointer;
}


.sign-up {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  border-radius: 5px;
  color: #ffffff;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;
}


.sign-up:hover {
  cursor: pointer;
  background: #f8f9fa;
  color: black;
}


.social-login {
  display: flex;
  justify-content: center;
  gap: 30px;
}


.social-login {
  height: 41px;
}

.social-login button{
  width: 230px;
  display: flex;
  align-items: center;
  gap: 35px;
  /* justify-content: center; */
}
.or {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}


.login-route {
  display: flex;
  justify-content: center;
  /* margin-top: 12.1vh; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}


.login-route span {
  color: rgba(97, 11, 238, 1);
  cursor: pointer;


}


.error-cont {
  display: flex;
  align-items: center;
  gap: 20px;
}


.error {
  border-color: red;
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 0.7vh;
  display: flex;
  align-items: center;
  gap: 5px;
}


.input-container,
.password-container {
  position: relative;
}


.input-container input {
  border: 1px solid #ccc;
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
}


.input-container.error input {
  border-color: red;
}


.password-container.error input {
  border-color: red;
}


.check-box {
  margin-top: 1.5vh;
  display: flex;
  align-items: flex-start;
  text-align: left;

}


.check-box .inp-check {
  border: 1px solid #ccc;
}


.check-box.error .inp-check {
  border-color: red;
}

input::placeholder {
  font-size: 16px;
 
  font-weight: 400;
  line-height: 24px;
}

.custom-google-login {
  /* Customize the button styles here */
  background-color: red;
  color: white;
  border: none;
  /* Add any other desired styles */
}

.google-button,.microsoft-button {
  border-radius: 4px;
  border: none;
  background: white;
  color: rgb(102, 102, 102);
  font-size: 14px;
  height: 40px;
  padding: 5px 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-family: "Google Sans", arial, sans-serif;
  border: 1px solid #dadbdb;
}
.google-button:hover,.microsoft-button:hover{
  background-color: #f5f8fd;
 
}

