.add-mem-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 115px;
    margin-top: 80px;

}

.add-mem-page-div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100vh;
}

.big-team-file {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 12px;
}

.inputs-array {
    margin-bottom: 6px;
    max-height: 300px;
    overflow-y: scroll;   
}

.big-team-file {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}

.bulk-upl {
    color: #610BEE;
    cursor: pointer;
}

.add-mem-div {
    display: flex;
    align-items: center;
    color: #FF7A7A;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 32px;
    cursor: pointer;
}

.btns-add-mem {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 16px;
}

.button-1,
.button-2 {
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-1 {
    border: none;
    background: #610BEE;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    width: 25.5vw;
    height: 5.6vh;
}

.button-2 {
    height: 5.6vh;
    width: 7vw;
    border: 1.5px solid #610BEE;
    color: #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}

.inputs-array {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.input-container-div input {
    padding: 10px 18px;
    width: 31.7vw;
    outline: none;
    border: 1px solid  #EDF2F7;
    background:  #FFF;
    border-radius: 5px;
    text-align: left;
    color: #001325;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}