.default-popup {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 19, 37, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.default-popup-container{
    background: white;
    border-radius: 15px;
    padding: 25px 15px;
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    width: 400px;
}
.default-message{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
    font-size: 16px;
}
.default-btns{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-end;
    gap: 20px;
     padding-top: 10px ;
}
.default-btns button{
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid #610bee;
    color: black;
    background: white;
    /* background: #610bee; */
}
.default-btns button:hover{
    background: #610bee;
    color: white;
}
.default-closing{
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}
.default-closing:hover{
background: rgb(217, 217, 217);
}
.closing-div{
    width: 100%;
    display: flex;
    justify-content: flex-end; 
}
.yes-btn{
    background: #610bee;
}