.depart-main {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 28px 40px;
  margin-top: 4.2vh;
  transition: width 0.3s ease;
  max-height: 88vh;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  box-sizing: border-box;
  /* border: 1px solid red; */
}

.spn-depart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.36px;
}

.prg-depart,
.no-tickets {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #001325;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.star-header {
  width: 2.3vw;
}

.id-header {
  width: 3.5vw;
}

.depart-header,
.lead-header,
.tck-header {
  width: 24.8vw;
}

.department-table-data th,
td {
  padding: 18px 12px;
}

.department-table-data th {
  background: white;
  text-align: left;
  color: #001325;
  border-bottom: 2px solid #edf2f7;
  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}
.tr-hover:hover{
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #5b89ff;
}

.avt-ld {
  display: flex;
  gap: 10px;
  align-items: center;
}

.avt-ld img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.avt-ld-span{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #610bee;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.pagination {
  display: flex;
  margin: auto;
  margin-top: 10px;
}

.prev-button,
.next-button {
  cursor: pointer;
  background: none;
  color: #FF7A7A;
  display: flex;
  gap: 8ps;
  align-items: center;
  padding: 10px 20px;
  height: 6vh;
  border: none;
}

.pagination-button {
  border: none;
  height: 6vh;
  width: 2.8vw;
  background: white;
}

.pagination-button.active {
  background-color: #FF7A7A;
  color: #fff;
  border-radius: 5px;
  border: none;
  height: 6vh;
  width: 2.8vw;
}

.dept-ticket-page {
  position: fixed;
  z-index: 6;
  background-color: rgba(0, 19, 37, 0.5);
  width: 100%;
  height: 100vh;
 
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dept-ticket-container {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  width: 60%;
  height: auto;
  /* position: absolute; */
  /* top: 20%;
  left: 25%; */
  margin: auto;
  display: flex;
  flex-direction: column;

}

.dept-ticket-top {
  display: flex;
  flex-direction: column;
  padding: 24px;
  text-align: left;
}

.dept-ticket-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #001325;
  /* Medium/20 */
  
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}

.dept-ticket-steps {
  color: #001325;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.dept-ticket-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 8px;
}

.dept-select {
  width: 24vw;
}

.dept-ticket-btns {
  padding: 24px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.dept-ticket-btns-next,
.dept-ticket-btns-cancel {
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.dept-ticket-btns-next {
  border: none;
  background: #CABDFC;
  color: #FFF;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.dept-ticket-btns-next.selected {
  background: #610BEE;
}

.dept-ticket-btns-cancel {
  background: white;
  border: 1.5px solid #7A5AF8;
  color: #7A5AF8;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}