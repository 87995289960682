.orgHome {
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  height: auto;
}

.orgTopNav {
  width: 100%;
  position: fixed;
  height: 65px;
  z-index: 2;
  top: 0;
  /* padding: 14px 30px 11px 5px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-bottom: 1px solid #edf2f7;
  /* height: 8vh; */
}

.rotated-dropdown {
  transform: rotate(-180deg);
  transition: transform 0.3s ease;
}

.rotated-reverse-dropdown {
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.org-logo {
  /* margin-left: 64px; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

}

.org-logo img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.icon-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 13px;
  margin-right: 64px;
}

.notifi-counting {
  display: flex;
  position: relative;
  cursor: pointer;
  padding: 5px;
}

.notifi-counting img {
  width: 25px;
  height: 25px;
}

.notif-count-num {
  background-color: red;
  color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;

}

.search {
  display: flex;
  width: 20vw;
}

.search input {
  border: none;
  width: 90%;
  border-radius: 30px;
  background: #f5f5f5;
  height: 100%;
  outline: none;
}

.btn-collab {
  position: absolute;
  z-index: 1;
  top: 100px;
  right: 50px;
  border: none;
  color: white;
  cursor: pointer;
  background: #610bee;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
}

.btn-back-ebom {
  position: fixed;
  z-index: 1;
  top: 100px;
  margin-left: 50px;
  /* left: 300px; */
  border: none;
  color: white;
  cursor: pointer;

  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  padding: 8px 12px;
}

.next {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding-top: 70px;
}

.profile-logo {
  background: #f5f5f5;
  border-radius: 8px;
  width: 50px;
  height: 50px;
}

.profile-org {

  position: relative;


}

/* left-sidebar */
.sidebar-menu {
  position: static;
  z-index: 1;
  width: 20vw;
  background-color: white;
  padding-left: 20px;
  padding-top: 84px;
  padding-right: 20px;
  border-right: 1px solid #e6e4f0;
  min-height: 100vh;
  height: auto;
  z-index: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.profile-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.org-name {

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #9896a3;
}

.org-type {

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #9896a3;
}

.profile-container {
  display: flex;
  gap: 10px;
}

.menu-details-part {
  margin-left: 20px;
}

.menu-details,
.menu-details-part {
  display: flex;
  gap: 5px;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 14px;
  flex: none;
  order: 1;
  flex-grow: 1;
  position: relative;
}

.menu-absolute-div {
  padding: 8px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
  position: absolute;
  left: 80px;
  z-index: 10;
  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.menu-absolute-div-sub {
  padding: 8px;

  position: absolute;
  left: 80px;
  top: 260px;
  z-index: 10;
  /* width: 280px; */
  display: flex;

  gap: 5px;
}

.menu-absolute-div-list {
  padding: 8px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);

  width: 220px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.absolute-menus {
  /* width: 220px; */
  padding: 8px 12px;
}

.absolute-menus:hover {}

.menu-details-part {
  justify-content: space-between;
  padding: 8px;
}

.menu {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 22px;
}

.menu-details:hover,
.menu-details-part:hover {
  cursor: pointer;
  background: #610bee;
  border-radius: 8px;
  color: white;
}

.menu-details:hover .menu-absolute-div-list {
  color: black;
  /* Ensure nested menus maintain the correct color */
}

/* .absolute-menus img {
  filter: brightness(10%) 
}
.absolute-menus:hover{
  filter: brightness(100%)
} */
.absolute-menus:hover {
  cursor: pointer;
  background: #610bee;
  border-radius: 8px;
  color: white;
}


.menu-details img,
.menu-details-part img {
  width: 25px;
  height: 25px;
  filter: brightness(0);
}

.menu-details:hover img,
.department-details:hover img,
.menu-details-part:hover img,
.absolute-menus:hover img {
  filter: invert(1) brightness(100);
}


.next-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #610bee;
  color: white;
  position: absolute;
  right: -1vw;
  top: 90px;

}

.prg-btn {
  width: 25px;
  height: 25px;
  color: black;
}

.department-details {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: auto;
  justify-content: space-between;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  flex: none;
  order: 1;
  flex-grow: 1;
  line-height: 20px;
}

.program-template {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 15px;
}

.department-details:hover {
  color: white;
}

.horizontalline {
  width: 17vw;
  border: 1px solid #e6e4f0;
}

.prg-org {
  display: flex;
  justify-content: space-between;
  padding-left: 35px;
  cursor: pointer;
  font-size: 14px;
 
}

.prg-org:hover {
  color: #610bee;
}

.online-status {
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  width: 8px;
  height: 8px;
  right: -2.5px;
  bottom: 5px;
  background: rgb(131, 212, 9);
}

/* margin bottom removed */
.prf-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border: 1px solid #edf2f7;
  border-radius: 8px;
  
}

.user-prf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.prf-picture {
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 50%;
}

.prf-picture img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.outline-menu {
  position: static;
  z-index: 1;
  width: 60%;
  padding-left: 20px;
  padding-top: 84px;
  padding-right: 20px;
  border-right: 1px solid #e6e4f0;
  min-height: 100vh;
  height: auto;
  min-height: 100vh;
}

.ticket-outline-menu {
  position: static;
  z-index: 1;
  width: 60%;
  padding-left: 20px;
  padding-top: 84px;
  padding-right: 20px;
  border-right: 1px solid #e6e4f0;
  height: auto;
  min-height: 100vh;
}

.horizontalline1 {
  width: 90%;
  border: 1px solid #e6e4f0;
}

.prev-btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #610bee;
  color: white;
  position: absolute;
  right: -2.2vw;
}

.menu1 {
  display: flex;
  flex-direction: column;
  width: 54px;
  gap: 5px;
  padding-top: 44px;
  padding-bottom: 22px;
}

.org-structure {
  padding-top: 200px;
  padding-left: 212px;

}

.headers-orgstruc {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  margin-bottom: 6vh;
}

.head-span {
  color: #000;
  font-size: 32px;

  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.para-span {
  font-size: 18px;

  line-height: 26px;
}

.buildandtemplate {
  display: flex;
  gap: 37px;
}

.build,
.template-div {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  border: 2px solid #EDF2F7;
  border-radius: 16px;
  padding: 32px 32px 32px 32px;
  width: 332px;
}

.build-img,
.template-img {
  width: 80px;
  height: 80px;
  /* margin-bottom: 3.6vh; */
}

.build-header {
  font-size: 20px;

  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.build-para {
  font-size: 14px;

  line-height: 20px;
}

.build:hover,
.template-div:hover {
  border: 2px solid #610BEE;
}

.template-page-creation {
  display: flex;
  width: 100%;
}

.template-page-side-left {
  width: 100%;
}

.template-sidebar-menu {

  width: 19vw;
  background-color: white;
  padding-left: 20px;
  padding-top: 84px;
  padding-right: 20px;
  border-right: 1px solid #e6e4f0;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: width 0.3s ease;
  padding-bottom: 50px;

}

.template-sidebar-menu::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Chrome, Safari, and Opera */
}

.template-sidebar-menu .horizontalline {
  width: 100%;
}

.menu-details.active,
.absolute-menus.active,
.department-details.active,
.menu-details-part.active {
  /* Add styles for the active menu item */
  cursor: pointer;
  background: #610bee;
  border-radius: 8px;
  color: white;
}

/* .menu-absolute-div.active{
  background: #610bee;
  color: white;
} */
.prg-org.active {
  cursor: pointer;
  color: #610BEE;
}

.menu-details.active img,

.department-details.active img,
.menu-details-part.active img {
  filter: invert(1) brightness(100);
}

.dropdown-part-create {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content {
  /* Add styles for the content area */
  flex-grow: 1;

}

.ticket-template-page {
  display: flex;
  position: relative;



}

.sidebar-collapsed {
  width: 7%;
}

.department-details:hover {
  cursor: pointer;
  background: #610bee;
  border-radius: 8px;
  color: white;
}

.department-details {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: auto;
  justify-content: space-between;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 14px;
  padding-top: 26px;
  flex: none;
  order: 1;
  flex-grow: 1;
  line-height: 20px;
}

.department-template {
  display: flex;
  gap: 5px;
  align-items: center;
}

.companyName-title {
  display: flex;
  flex-direction: column;
  text-align: left;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: black;
}

.top-nav-memberPhoto {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
}

.top-nav-name-memberPhoto {
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  text-align: center;
}

.member-hover-details {
  display: none;
  position: absolute;
  z-index: 5;
  bottom: -50px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  align-items: center;
  border-radius: 8px;

}

.back-to-home {
  display: flex;
  align-items: center;
  padding: 12px;
  width: 100%;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid  #EDF2F7;
  background: #FFF;
  box-sizing: border-box;
  cursor: pointer;
}
.details-sidebar-div{
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.top-nav-memberPhoto:hover+.member-hover-details {
  display: flex;

}