.download-po-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 0px;
    padding: 48px;
    gap: 20px;
}
.download-po-title-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 5px;
}
.download-po-title-span{
    font-size: 28px;
    font-weight: 800;
}
.download-po-title-span-2{
    font-size: 24px;
    font-weight: 800;
    margin-top: 15px;
    margin-bottom: 20px;
}
.download-po-address-title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.po-download-supply-page{
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;

}
.po-download-supply-details{
    display: flex;
    width: 100%;
    gap: 5px;

}
.po-download-supply-details-key{
    width: 20%;
    color: #616161;
}
.po-download-supply-details-value{
   color: black;
   font-weight: 600;
}
.po-download-supply-fields-heads-details{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* color: #001325; */
.po-tandc-page{
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;
}
.po-tandc-page-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px;
    border: 1px solid grey;
    border-radius: 8px;
}
.po-tandc-page-head{
    font-size: 16px;
    font-weight: 800;
}
.po-tandc-page-desc{
    font-size: 12px;
    font-weight: 600;
   
}
.po-tandc-page-msg{
    font-size: 12px;
}


.po-download-heads{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}