.part-num-page {
  margin-top: 80px;
  width: 100%;
  padding: 20px 20px 0px 20px;
  box-sizing: border-box;
}



/* style={{color:'red',fontSize:'12px',fontWeight:'500',width:'30px'}} */
.unique-error {
  color: #FF3B2F;
  text-align: right;


  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  width: 130px;
}

.part-num-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}




.part-num-content {
  display: flex;
  align-items: center;
  gap: 16px;
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  /* 133.333% */
  letter-spacing: -0.288px;
}




.part-num-line {
  width: 1px;
  height: 20px;
  background: #edf2f7;
}




.part-num-active {
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  border: none;
  color: #fff;
  cursor: pointer;
}




.part-num-active {
  background: #610bee;
}




.part-num-inactive {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%),
    #610bee;
  /* display: none; */
}




.part-num-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80.5vh;
}




.part-num-bottom-left {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  min-height: 80.5vh;
  width: 25%;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
  gap: 20px;
}




.part-num-bottom-right {
  background: white;
  width: 80%;
  height: 80.5vh;

  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  box-sizing: border-box;
}




.part-num-bottom-left-text {
  display: flex;
  flex-direction: column;
  text-align: left;
}




.part-num-bottom-left-title {
  color: #001325;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}




.part-num-bottom-left-desc {
  color: #001325;




  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}




.part-num-bottom-left-dragable {
  display: flex;
  flex-direction: column;
  gap: 8px;
}




.part-num-bottom-left-items {
  border-radius: 8px;
  border: 1px solid #edf2f7;
  background: var(--Surface-0, #fff);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.part-num-bottom-left-items img {
  width: 25px;
  height: 25px;
}



.part-num-bottom-left-msg {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}




.part-num-bottom-nomenclature {
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  text-align: left;
  border-radius: 12px;
  border: 1px solid #edf2f7;
}




.part-num-bottom-right-title {
  color: #001325;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}




.part-num-bottom-right-desc {
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.part-num-bottom-right-desc-high {
  color: rgba(72, 123, 253, 1);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}


.part-num-bottom-partName {
  display: flex;
  flex-direction: column;
  padding: 8px 24px;
  border: 1px solid #edf2f7;
}

.part-num-bottom-addition {
  border-radius: 8px;
  background: #F6F6F6;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* margin-top: 30px; */
  /* border: 1px solid red; */
  /* width: 10px;
  height: 10px; */
}


.part-num-bottom-partName-desc {
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 233.333% */
  letter-spacing: -0.18px;
}




.part-num-bottom-right-partName-title {
  color: #001325;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}




.part-num-bottom-cont {
  display: flex;
  /* height: 100%; */
  /* flex-wrap: wrap; */
  align-items: center;
  /* justify-content: space-between; */
  gap: 8px;
  width: 100%;
  padding: 4px;
  /* position: relative; */
  /* height: 200px; */
  box-sizing: border-box;


  border-radius: 8px;
  /* border: 1px solid #5B89FF; */
  background: #FFF;

}


.part-num-bottom-alpha-divs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* justify-content: space-between; */

  width: 100%;
  box-sizing: border-box;
  gap: 10px;
}







.part-num-bottom-btn-cancel {
  width: 32px;
  height: 32px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #5B89FF;
  padding: 4px;
  background: #FFF;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.part-num-bottom-btn-cancel-img {
  width: 16px !important;
  height: 16px !important;
}


.part-num-bottom-box {
  cursor: grab;
  height: 100%;
  /* width: 23px; */
  padding: 8px 6px;
  border-radius: 4px 0px 0px 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #5B89FF;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  box-sizing: border-box;
}

.dragging-over-line {
  /* visibility: visible; */
  border-bottom: 51px solid #610bee;
  box-shadow: 0px 2px 5px rgba(97, 11, 238, 0.5);
}

.not-dragging-over-line {
  border-bottom: 1px solid #5B89FF;
}

.part-num-bottom-alpha-title {
  font-size: 14px;
  font-weight: 500;
}

.part-num-bottom-alpha,
.part-num-bottom-alpha-select {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  /* width: 150px; */
  /* border: 1px solid red; */
  height: 100%;
}

/* .part-num-bottom-alpha-select{
  width: 220px;
} */
.part-num-bottom-alpha-radio {
  display: flex;
  flex-direction: column;
  /* text-align: left; */
  justify-content: center;
  align-items: center;
  gap: 10px;
}



.saved-text-part {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #610BEE;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.part-num-bottom-btn-fixed {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  /* height: 60%; */
  box-sizing: border-box;
  padding: 0px 2px;
  /* padding-bottom: 5px; */
  border-radius: 6px;
  border: 1px solid #610BEE;
}




.part-num-bottom-btn-fixed button {
  border-radius: 4px;
  background: #610BEE;
  padding: 6px;
  /* width: 153px; */
  border: none;
  color: white;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  box-sizing: border-box;
}


.empty-drop-message {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}


.part-number-dropping-zone {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* border: 1px solid red; */
  /* gap: 24px; */
  gap: 10px;
  box-sizing: border-box;
  height: 65vh;
  overflow-y: scroll;
}


.part-num-bottom-alpha-desc {
  width: 150px;
  box-sizing: border-box;
  padding: 8px;
  outline: none;
  border: 1px solid #edf2f7;
  border-radius: 4px;

}

.part-num-bottom-alpha-desc::placeholder,
.part-num-bottom-alpha-desc-mean::placeholder {
  font-size: 12px;
}

.part-num-bottom-alpha-char {
  width: 200px;
  box-sizing: border-box;
  padding: 10px 16px;
  outline: none;
  border: 1px solid #edf2f7;
  border-radius: 4px;
}

.part-num-bottom-alpha-desc-mean {
  width: 150px;
  box-sizing: border-box;
  padding: 10px;
  outline: none;
  border: 1px solid #edf2f7;
  border-radius: 4px;
}

.part-num-bottom-top {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.part-num-bottom-totype {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
}

.part-num-bottom-top img,
.part-num-bottom-totype img {
  width: 24px;
  height: 24px;
}

.fixed-variables-list {
  border-radius: 100px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
}

.fixed-variables-div {
  width: 100%;
  display: flex;
  align-items: center;

}

.view-all-btn {
  display: flex;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #610BEE;
  background: #FFF;
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

}

.part-num-bottom-alpha-radio input[type="radio"] {
  width: 32px;
  /* Adjust the width based on your design */
  height: 32px;
  /* Adjust the height based on your design */
  cursor: pointer;
  appearance: none;
  border: 2px solid rgba(8, 8, 8, 1);
  /* Border color */
  border-radius: 50%;
  /* Make it circular */
  outline: none;
}

/* Style the custom radio button when checked */
input[type="radio"]:checked {
  background-color: #2900f6;
  /* Background color when checked */
}

.fixed-variable-text-error {
  color: #FF3B2F;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}


.part-num-top-btns {
  display: flex;
  gap: 12px;
  align-items: center;
}

.part-num-attributes {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
  border-radius: 4px;
  border: 1px solid #610BEE;
  background: white;
  padding: 8px;
}