.overview-page{
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: auto;
    align-items: center;
    background: #F6F6F6;
   height: 78vh;
   /* border: 1px solid red; */
   overflow-y: auto;
    width: 100%;
}
.overview-headers{
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
}
.start-overview{
    color: #001325;
    font-size: 24px;
font-style: normal;
font-weight: 600;
line-height: 28px; /* 116.667% */
letter-spacing: -0.288px;
}

.start-overview-sub{
    color:rgb(128, 128, 128);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.overview-status-track{
    width: 45.6vw;
    border: 1px solid black;
    background: white;
    border-radius: 8px;
    border: 1px solid  #EDF2F7;
}
.overview-marathon-setup{
    margin: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 24px 24px 10px;
}
.status-tracker{
    width: auto;
    height: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #16A0A0;
    /* border: 5px solid green; */
}
.marathon-text{
    color:  #001325;
    text-align: center;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
    letter-spacing: -0.3px;
}
.marathon-steps{
    color:  #001325;
    text-align: center;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
}

.overview-status-checks{
    display: flex;
    flex-direction: column;
}
.overview-login-check{
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
}
.overview-login-details{
    display: flex;
    align-items: center;
    gap: 8px;
}
.overview-details{
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.overview-details-head{
    color:  #001325;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
    letter-spacing: -0.192px;
}
.overview-details-sub{
    color:  #001325;
    
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
.overview-status-button{
    padding: 8px 12px;
    color:  #FFF;
/* Regular/12 */

font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
    border: none;
    background:  #610BEE;
    border-radius: 4px;
}
.overview-status-button:hover{
   cursor: pointer;
}