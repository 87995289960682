.create-po-page {
    margin-top: 70px;
    width: 100%;
    padding: 24px;
    box-sizing: border-box;
    overflow-y: scroll;
    height: 89vh;
    overflow-x: hidden;
}

.create-grn-btn-po {
    color:  #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    border-radius: 4px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
    display: flex;
    align-items: center;
    border: none;
    padding: 10px 20px;
}

.create-po-page-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.create-po-title {
    display: flex;
    align-items: center;
    gap: 32px;
    color: #001325;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.288px;
}

.create-po-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.create-po-btns button {
    border-radius: 4px;
    border: none;
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 12px;
}

.create-po-page-bottom {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.create-po-page-bottom-select {
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 40px;

}

.create-po-page-bottom-fileds-cont {
    display: flex;
    flex-direction: column;
    width: 100%;

}

.horizontal-line-po {
    border: 1px solid #E6E4F0;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}


/* supplychain-fileds */
.supply-fields-heads {
    display: flex;
   
    justify-content: space-between;
    width: 100%;

}

.supply-fields-heads-left {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 50%;
    text-align: left;
    align-items: flex-start;
}

.supply-fields-heads-details {
    display: flex;
    align-items: center;
    gap: 5px;
}

.supply-field-key {
    color: #616161;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.supply-field-value {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}

.po-excell-page {
    border: 1px solid red;
    width: 100%;
}


/* po-template-mandatory fields */
.po-template-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 28px;
    width: 100%;
    /* padding: 24px; */
    align-items: center;
}

.po-mandatory-field-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    /* border: 1px solid red; */
    height: 100px;

}
.supplier-mandatory-field-container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}
.supplier-mandatory-field-container input{
    width: 292px;
    padding: 10px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #EDF2F7;
    background: #FFF;
}
.po-mandatory-field-container input,.po-mandatory-field-container textarea {
    width: 292px;
    padding: 10px 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #EDF2F7;
    background: #FFF;
}

.po-table {
    width: 100%;
    border-collapse: collapse;
}

.po-table-cell-header,
.po-table-cell {
    padding: 8px;
    border: 1px solid #ddd;
}

.po-table-cell-header {
    background-color: #f0f0f0;
    text-align: left;
}

.po-input {
    width: 100%;
    box-sizing: border-box;
    border: none;
    outline: none;
    padding: 6px 8px;
    font-size: inherit;
}

.suggestions-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* align-items: center; */
    position: absolute;
    z-index: 1;
    top: 35px;
    border: 1px solid #EDF2F7;
    padding: 5px;
    background: white;
    color: black;
    text-align: left;
    border-radius: 8px;
    max-height: 30vh;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.20);
    overflow-y: auto;
}

.suggestion-item {
    padding: 5px;
    background: white;
   
}

.suggestion-item:hover {

    cursor: pointer;
    background: #d3eaf1;
}

.view-grns-page-div-relative button {
    position: relative;
}

.view-grns-page-div-absolute {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 150px;
    background: white;
    border-radius: 8px;
    /* padding: 12px 16px; */
    box-sizing: border-box;
    width: 280px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 1;
}
.grn-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;

}
.grn-item:hover{
    background: #d3eaf1;
    cursor: pointer;
}
.view-all-btn-grn{
    padding: 12px 16px;
}
.view-all-btn-grn span:hover{
    color: blue;
    text-decoration: underline;
}



.po-changes-btns{
    display: flex;
    align-items: center;
    gap: 12px;

}

.po-changes-save-btns{
    background: #610bee;
    border: 1px solid #610bee;
    color: white;
    border-radius: 4px;
   
    padding: 10px 16px;
}
.po-changes-cancel-btns{
    padding: 10px 16px;
    background: white;
    color: #610bee;
    border: 1px solid #610bee;
    border-radius: 4px;
}






.loader-po {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height:20px;
    animation: spin 1s linear infinite;
}
.loader-po-div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 170px;
    background: white;
    /* border: 1px solid red; */
    /* height: 40px; */
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.date-range-input-relative{
    position: relative;
}