.privacy-policy{
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    margin-top: 50px;
}
.privacy-policy ul{
    margin-left: 20px;
}
.privacy-policy a{
    color: blue;
}
.privacy-info{
    margin-left: 20px;
}