.contactPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.5) 100%),
    #610bee;
    padding-bottom: 50px;
    box-sizing: border-box;
    padding: 30px 63px;
    /* border: 1px solid red; */
}

.contactPage-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
  padding: 72px 0px;
  box-sizing: border-box;
  /* height: 54vh; */
}

.contactPage-bottom {
  display: flex;
  /* padding: 0px 19.5vw; */
  height: 31.8vh;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contactPage-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 70px;
  /* 145.833% */
  letter-spacing: -0.96px;
}

.contactPage-hardware {
  font-weight: 700;
}

.contactPage-btn button {
  margin-top: 20px;
  padding: 12px 32px;
  border-radius: 4px;
  border: 1.5px solid white;
  cursor: pointer;
  background: inherit;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.192px;
}

.contactpage-title,
.contactpage-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  gap: 7px;

}

.contactpage-title span {
  color: #fff;
  font-family: Manrope;
  padding-left: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 162.5% */
}

.contactpage-list-head {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16.8px;
  /* 120% */
}

.contactpage-list-iteams {
  color: rgba(255, 255, 255, 0.85);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16.8px;
  /* 120% */
}

/* .contactpage-title img {
  width: 13vw;
  height: 9.2vh;
} */
.contactpage-title img{
  width: 325px;
  height: 110px;
}


@media screen and (max-width:1000px) {
  .contactPage-content {
    font-size: 36px;
  }
}

@media screen and (max-width:700px) {
  .contactPage-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
  .contactPage-btn {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .contactPage-btn button{
    padding: 10px 20px;
  }
  .contactPage-top{
    padding-left: 24px;
  }

}
@media screen and (max-width:700px){
  .contactPage-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
    gap: 30px;
    height: 316px;
    
  }
  .contactpage-title img{
    width: 250px;
    height: 100px;
  }
  .contactpage-list{
    padding-left: 25px;
  }
}