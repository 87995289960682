.part-number-list-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 28px 40px;
  margin-top: 80px;
  height: 80vh;
  overflow-y: scroll;
}
.part-number-list-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.part-number-list-title span {
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.288px;
}
.part-number-list-btns {
  display: flex;
  align-items: center;
  gap: 11px;
}
.part-number-list-title button {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #610bee;
  border: none;
}
.part-number-list-filters {
  display: flex;
  align-items: center;
  gap: 12px;
}
.part-number-list-spn {
  display: flex;
  align-items: center;
  gap: 12px;
}
.part-number-list-search{
    display: flex;
    width: 280px;
    height: 40px;
    align-items: center;
    gap: 10px;
    padding: 15px;
    border: 1px solid #edf2f7;
    border-radius: 30px;
    background: white;
    box-sizing: border-box;
}
.part-number-list-search input {
  outline: none;
  border: none;
}
.part-number-list-spn span {
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #edf2f7;
  background-color: white;
  color: #001325;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}
.part-number-list-spn span:hover,.part-number-list-spn span.active {
  cursor: pointer;
  border: 1px solid #610bee;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #610bee;
  color: #610bee;
}
.part-number-list-table-container {
  width: 100%;
}
.part-number-list-table-container thead {
  font-size: 14px;
  font-weight: 500;
}
.part-number-list-table-container tbody {
  font-size: 14px;
  font-weight: 400;
}

.part-number-list-table-container th, td {
  /* height: 52px;
  box-sizing: border-box; */
  padding: 10px 12px;
  /* padding: 10px 2px; */
}
.part-number-list-table-container th {
  background: white;
  border-bottom: 2px solid #edf2f7;
  text-align: left;
  color: #001325;
  font-size: 14px;
  /* font-weight: 500; */
  line-height: 20px;
  letter-spacing: -0.14px;
}
.part-number-list-table-container-btn {
  border-radius: 4px;
  border: 1.5px solid #610bee;
  background: #fff;
  display: flex;
  color: #610bee;
  padding: 8px 12px;
  align-items: center;
  justify-content: center;
}
.part-number-photo{
  display: flex;
  align-items: center;
  gap: 10px;
}
.part-user-photo{
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #610bee;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}