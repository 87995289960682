.faqpage {
  padding: 80px 220px;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-between;
  /* height: 82vh; */
  width: 100%;
  box-sizing: border-box;
}

.faqpage-left-container,
.faqpage-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  gap: 20px;
  width: 40%;
}

.faq-title {
  color: #001325;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 63.36px;
  /* 132% */
  letter-spacing: -0.48px;
}

.faqpage-visit {
  color: rgb(58, 58, 58);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: -0.5px;
}

.faqpage-help-center {
  color: #001325;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: -0.5px;
}

.faqpage-questions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  transition: max-height 0.3s ease;
  /* padding: 20px 0px; */
}

.faqpage-questions img {
  width: 40px;
  height: 39px;
  cursor: pointer;
}

.faqpage-questions span {
  color: #001325;
  /* padding: 20px 0px; */
  /* Medium/20 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}
.faq-ques-ans{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.faq-answer{
  color: #8d8c8c;
}
@media screen and (max-width:1000px) {
  .faqpage {
    padding: 40px 120px;
    flex-direction: column;
    gap: 24px;
  }

  .faqpage-left-container,
  .faqpage-right-container {
    width: 100%;
  }
}

@media screen and (max-width:600px) {
  .faqpage {
    padding: 48px 24px;
    flex-direction: column;
    gap: 24px;
  }

  .faqpage-questions span {
    font-size: 16px;
    line-height: 28px;
  }

  .faqpage-visit, .faqpage-help-center {
    line-height: 20px;
  }
}