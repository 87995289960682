.tck-fltr {
  display: flex;
  gap: 25px;
  align-items: center;
}

.filter-by-dept {
  width: 15vw;
  border-radius: 50%;
}

.reset-filters button {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background: blue;
  color: white;
  font-weight: 500;
  border: none;
  cursor: pointer;
}

.member-item {
  position: relative;
  margin: 5px;
}

.member-name {
  display: none;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
}

.member-item:hover .member-name {
  display: block;
}
.ticket-memberPhoto{
  width: 24px;
  height: 24px;
  border-radius: 50%;
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  text-align: center;
  /* font-size: 14px; */
}
.show-more-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  color: #001325;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
}

.members-photo-details {
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px;
}

.members-photo-details .member-item {
  margin-right: -15px;
  /* Adjust the overlap */
}
.security-icon1 img{
width: 100%;
}
.members-dropdown {
  display: flex;
  align-items: center;
  width: 10vw;
  cursor: pointer;
}

.members-drop-div {
  position: absolute;
  z-index: 6;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border: 1px solid #EDF2F7;
  background: white;

}

.reset-btn {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: #001325;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  cursor: pointer;
  border-radius: 30px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.depart-header{
  width: 90vw;
  position: relative;
}
.id-header{
  width: 25vw;
}
.view-more-text{
  background: inherit;
  border: none;
  cursor: pointer;
  padding: 10px;
  color: blue;
  font-weight: 500;
  font-size: 15px;
  
}
.view-more-text:hover{
  text-decoration: underline;
}
/* no tickets */


.no-tickets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 40px;
}

.no-ticket-view-right {
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  justify-content: flex-start;
  color: #001325;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  gap: 10px;
}

.no-ticket-progress {
  color: #001325;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.288px;
}

.no-tct-hm-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding-left: 250px; */
  /* border: 1px solid green; */
}

.no-tct-hm-btn span {
  color: rgba(8, 7, 40, 0.64);
  text-align: center;
  /* width: 100%; */
  /* border: 1px solid red; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}

.no-tct-hm-btn button {
  display: flex;
  padding: 10px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #610BEE;
  border: none;
  color: var(--text-white, #FFF);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  cursor: pointer;
}


/* ticket title  */
.tkt-title-cont{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 19, 37, 0.5);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tkt-title-box{
  width: 30vw;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  /* border: 1px solid black; */
  background: white;
  position: absolute;
  height: 25vh;
 
  
}
.tkt-heading{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 10px 10px;
}
.horizontalLine{
  width: 100%;
  border: 1px solid #610BEE;
  /* border-radius: 50%; */
}
.tkt-name-text {
  /* padding-top: 10px;
  padding-left: 10px; */
  margin-top: 5px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  flex-wrap: wrap; /* Allow the content to wrap onto multiple lines */
}
.tkt-name-text textarea{
  /* padding-top: 10px; */
  width: 100%;
  font-size: 18px;
  border-radius: 4px;
  resize: none;
  /* line-height: 2.5; */
  /*  */
  /* font-size: 12px; */
  padding-top: 50px;
  padding: 50px 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  border: none;
  outline: none;
}

.close-icon{
  cursor: pointer;
}




.inventory-list{
  width: 100%;
  margin-top: 80px;
  padding: 24px;
  box-sizing: border-box;
  max-height: 85vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.inventory-table{
  width: 100%;
}
.inventory-table table{
  text-align: left;
}
.inventory-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}
.inventory-view-top-btns{
  display: flex;
  align-items: center;
  gap: 5px;
}
.inventory-quantity{
  display: flex;
  flex-direction: column;
  color:  #001325;

font-style: normal;
font-weight: 500;
line-height: 32px; /* 133.333% */
letter-spacing: -0.288px;
margin-bottom: 15px;
}
.inventory-quantity-qty{
  font-size: 24px;
}

.link-po{
  color: blue;
}
.link-po:hover{
  text-decoration: underline;
}

/* Container for the entire popup */
.popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4); /* Dimmed background */
  z-index: 9999;
  overflow: hidden; /* Prevent unnecessary scrollbars */
}

/* Box containing the popup content */
.popup-box {
  background-color: #fff;
  width: 380px;
  max-width: 90%; /* For responsiveness */
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px;
  box-sizing: border-box;
}

/* Header with title and close button */
.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.popup-close-icon {
  cursor: pointer;
  font-size: 24px;
  color: #666;
}

/* Divider line */
.popup-divider {
  height: 2px;
  background-color: #6200ea;
  width: 100%;
  margin: 0;
}

/* Content area for the textarea */
.popup-content {
  width: 100%;
  margin-top: 10px;
  box-sizing: border-box;
}

/* Styling for the auto-resizing textarea */
.popup-textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  font-size: 16px;
  background-color: #f9f9f9;
  outline: none;
  transition: border-color 0.3s;
  resize: none;
  overflow: hidden; /* Prevent overflow */
  min-height: 80px;
  max-height: 300px;
  box-sizing: border-box; /* Ensure padding is included in height */
  line-height: 1.5; /* Ensure consistent line spacing */
}

/* Highlight border on focus */
.popup-textarea:focus {
  border-color: #6200ea;
/* WAREHOUSE LIST  */
}
.warehouse-list-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
}

.warehouse-table {
  width: 100%;
  border-collapse: collapse;
}

.warehouse-table th,
.warehouse-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
}

.warehouse-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.warehouse-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.loader {
  text-align: center;
  padding: 20px;
  font-size: 16px;
}

.end-of-list {
  height: 20px;
  visibility: hidden;
}
