.tour-btns{
    /* margin-top: 40px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 50px;
    margin-top: 30px;
}
/* className="tour-next" */
.tour-btns button{
    padding: 8px 16px;
    border: 1px solid #610BEE;
    border-radius: 5px;
    font-size: 16px;
}
.tour-next{
    background: #610BEE;
    color: white;
}
.tour-back{
    background: rgb(236, 234, 234);
    color: #610BEE;
}
