/* .loginpass{
    /* border: 1px solid #E6E4F0; 
    display: flex;
    width: 99%;
    height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    border: 1px solid #9E9E9E;
    border-radius: 5px;

}
.loginpass button{
    border: none;
    background: none;
}
.loginpass input{
    border: none;
    width: 100%;
    outline: none;
    border-radius: 5px 0px 0px 5px;
    height: 90%;
} */


.loginpass{
    display: flex;
    width: 100%;
    height: 50px;
    border: 1px solid gray;
    border-radius: 5px;
}
.loginpass{
    border: none;
    outline: none;
    height: auto;
}
.loginpass button{
    border: none;
    background: none;
}