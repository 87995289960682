.privacy-page {
    width: 60.2vw;
}

.privacy-password,
.privacy-delete {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px;
}

.privacy-img-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.privacy-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
}

.privacy-password button {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 4px;
    background: #610BEE;
    border: none;
    color: var(--text-white, #FFF);
    /* Medium/14 */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;

}

.privacy-delete button {
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 4px;
    background: #FF3B2F;
    border: none;
    color: var(--text-white, #FFF);
    /* Medium/14 */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}

.privacy-content-head {
    color: #001325;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.192px;
}

.privacy-content-desc {
    color: #001325;
    /* Regular/14 */
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}