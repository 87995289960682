.team-rich-page {
  background: white;
  padding: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}
.team-rich-img-mb{
  display: none;
}

.team-rich-container {
  border-radius: 20px;
  background: var(--Primary-800,
      linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%),
      #610bee);
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 120px; */
  width: 100%;
  box-sizing: border-box;
  padding: 20px 80px;
  /* height: 768px; */
}

.team-rich-content {
  width: 50vw;
  /* padding: 120px 0px 120px 0px; */
  /* align-items: center; */
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* justify-content: left; */
  /* border: 5px solid yellow; */
}

.team-rich-head {
  color: #ffcf01;
  /* font-family: Manrope; */
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  align-self: stretch;
  line-height: normal;
}

.team-rich-title {
  color: #fff;
  font-size: 48px;
  font-family: Syne;
  font-weight: 700;
}

.team-rich-desc {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  font-weight: 400;
}

.team-rich-btn {
  padding: 12px 32px;
  background: inherit;
  color: #fff;
  border: 1.5px solid white;
  border-radius: 4px;
  cursor: pointer;
  width: 184px;
  font-size: 16px;
  font-weight: 400;
}

.team-rich-img {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  height: 600px;
  width: 75%;
  /* border: 5px solid yellowgreen; */
}

/* .team-rich-img img{
    width: 80%;
    height: 100%;
} */


/* .team-rich-title {
  color: #fff;
  font-size: 48px;
  font-family: Syne;
  font-weight: 700;
} */
@media screen and (max-width:1550px){
  .team-rich-title {
    
    font-size: 48px;
  }
}
@media screen and (max-width:1000px) {
  .team-rich-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* gap: 120px; */
    width: 100%;
    box-sizing: border-box;
    padding: 20px 60px;
  }
  .team-rich-content {
    width: 100%;
  }
  .team-rich-img{
    width: 100%;
  }
  .team-rich-title{
    font-size: 32px;
  }
  .team-rich-desc{
    font-size: 16px;
  }
  .team-rich-btn {
    padding: 10px 28px;
    /* width: 154px; */
    font-size: 14px;
  }
}
@media screen and (max-width:700px){
  .team-rich-page{
    padding: 24px;
  }
  
  .team-rich-img{
    display: none;
  }
  .team-rich-container {
    padding: 20px 24px;
  }
  .team-rich-title{
    font-size: 24px;
  }
  .team-rich-desc{
    font-size: 12px;
  }
  .team-rich-img-mb {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* 364×294 */
    display: flex;
    width: 350px;
    height: 322px;
    /* border: 5px solid yellowgreen; */
  }
  .team-rich-head{
    font-size: 8px;
  }
}
