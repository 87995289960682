/* text and para fields */
.template-text-box-container {
  display: flex;
  flex-direction: column;
  /* margin-top: 9.7vh; */
  align-items: flex-start;

  text-align: left;
  padding-left: 20px;
  /* border: 1px solid black; */
  width: 30%;
  background: #f6f6f6;
  height: 100vh;
  overflow-y: scroll;
}

.template-text-box-container-heading {
  padding: 20px 0px;

}

.template-text-box-container-heading span {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
}

.template-text-box-container-mandatory-check {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-left: 5px;
}

.template-text-box-container-mandatory-check span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
}

.template-text-box-container-texttype {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.template-text-box-container-field-input {
  display: flex;
  margin-left: 10px;
  border-radius: 4px;
  border: 1px solid #edf2f7;
  background: white;
  padding: 10px 16px;
  box-sizing: border-box;
  width: 21vw;
}

.template-text-box-container-field-input input {
  width: 100%;
  border: none;
  outline: none;
}

.template-text-box-container-text-switches {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}

.template-text-box-container-switches {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 16px;
}

.template-text-box-container-characters {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  /* margin-right: 12px; */
}

.template-text-box-container-characters-div {
  display: flex;
  justify-content: space-between;
  width: 17vw;
  box-sizing: border-box;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  padding: 10px;
  color: #001325;
  /* Regular/14 */

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.template-text-box-container-number {
  /* width: 85%; */
  border: none;
  outline: none;
}

.template-text-box-container-values {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 20vw;
}

.template-text-box-container-values span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.switch {
  width: 36px;
  height: 20px;
  border-radius: 10px;
  background-color: #e9e9e9;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
}

.circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.2s;
}

.switch.on {
  background-color: #ff7a7a;
}

.switch.on .circle {
  left: 18px;
}

.collab-members-btns1 {
  position: fixed;
  bottom: 0px;
  display: flex;
  padding: 12px 16px;
  background: white;
  justify-content: space-between;
  align-items: center;
  height: 8.5vh;
  bottom: 0;
  background: #f6f6f6;
}

.collab-btns1 {
  display: flex;
  gap: 8px;
}

.collab-btns1 button {
  border-radius: 4px;
  border: none;
  height: 4.8vh;
  padding: 8px 12px;
}

.btn-sv1 {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.6) 0%,
      rgba(255, 255, 255, 0.6) 100%),
    #610bee;
  color: white;
  cursor: pointer;
}

.btn-sv2 {
  background: #610BEE;
  color: white;
}

.btn-cl1 {
  color: #610bee;
  background: white;
}

.btn-cl1:hover {
  border: 1px solid #610bee;
  cursor: pointer;
}

/* NUMBERS TEMPLATE */

.template-text-box-container-numbers {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.template-text-box-container-numbers span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

/* SINGLE SELECT  and MULTI SELECT*/
.template-text-box-container-text-singleselect-boxes {
  display: flex;
  align-items: center;
}

.template-text-box-container-text-singleselect-boxes img {
  cursor: pointer;
}

.template-text-box-container-text-singleselect {
  display: flex;
  padding: 10px 10px;
  gap: 12px;
  justify-content: space-between;

  box-sizing: border-box;
  margin-left: 10px;
  width: 20vw;
  border-radius: 4px;
  border: 1px solid #edf2f7;
  background: #fff;
}

.template-text-box-container-text-singleselect input[type="text"] {
  width: 100%;
  border: none;
  outline: none;
}

.template-text-box-container-text-singleselect-adding {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-text-box-container-text-singleselect-adding-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: -0.14px;
  color: #610bee;
  cursor: pointer;
}

/*  DATE TEMPLATE  */

.template-text-box-container-date {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.template-text-box-container-date span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #001325;
}