.reviews-page {
  display: flex;
  flex-direction: column;
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 0.1) 100%),
    #610bee;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  /* height: 81.7vh; */
  gap: 68px;
  box-sizing: border-box;
  padding: 25px;

  /* overflow: auto; */
}

.reviews-page-title {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  color: #fff;
  font-family: Syne;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 42.24px;
  /* 132% */
}

.reviews-page-btns div {
  background: #fff;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  width: 46px;
  height: 46px;
  /* padding: 9px 7.5px 9px 7.5px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.reviews-page-btns {
  display: flex;
  gap: 16px;
  align-items: center;
}

.reviews-page-btns img {
  width: 47px;
  height: 47px;
}

.reviews-page-bottom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 78px;
}

/* .reviews-page-bottom img {
  width: 124px;
  height: 43px;
} */

.reviews-page-bottom button {
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.09);
  border: none;
  cursor: pointer;
  padding: 12px 24px;
  color: #fff;
  font-family: Inter;
  font-size: 16.172px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  /* 111.304% */
  letter-spacing: -0.48px;
  width: 124px;
  height: 43px;
}

.review1 {
  width: 260px;
  height: 59px;
}

.review2 {
  width: 100px;
  height: 50px;
}

.review3 {
  width: 220px;
  height: 52px;
}

.reviews-page-cards {
  display: flex;
  align-items: center;
  width: 100%;
  /* padding: 10px; */
  gap: 40px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-bottom: 25px;
  padding-left: 10px;
  cursor: move;
}

.reviews-page-cards::-webkit-scrollbar {
  display: none;
}

.reviews-cards-container {
  background: white;
  width: 460px;
  height: 177.06px;
  border: 1px solid #610bee;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 26px 0px 20px 0px;
  box-sizing: border-box;
  position: relative;
  word-wrap: break-word;
  /* word-break: keep-all; */
}

.reviews-cards-top {
  /* border: 1px solid red; */
  width: 460px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: start;
  gap: 10px;
  padding-right: 78px;
  padding-right: 23px;
  box-sizing: border-box;
  text-wrap: wrap;
}

.reviews-cards-bottom {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  padding-left: 57px;
}

.reviews-cards-top-1 {
  color: #610bee;
  font-family: Inter;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 100% */
  letter-spacing: -0.25px;
}

.reviews-cards-top-2 {
  color: #001325;
  font-family: Inter;
  font-size: 20.65px;
  font-style: normal;
  font-weight: 500;
  line-height: 25.76px;
  /* 124.746% */
  letter-spacing: -0.25px;
}

.reviews-cards-bottom1 {
  color: #001325;
  font-family: Inter;
  font-size: 16.5px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.36px;
  /* 117.333% */
  letter-spacing: -0.48px;
}

.reviews-cards-bottom2 {
  color: rgb(78, 78, 78);
  font-family: Inter;
  font-size: 15.375px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.6px;
  /* 114.472% */
  letter-spacing: -0.48px;
}

.reviews-cards-img {
  position: absolute;
  left: -11px;
  bottom: -11px;
  /* border: 1px solid white; */
  background-color: grey;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  border: 4px solid #fff;
  /* background: url(<path-to-image>), lightgray 0px 0px / 100% 100% no-repeat; */
  box-shadow: 0px 4px 11px 0px rgba(208, 200, 187, 0.33);
}

@media screen and (max-width: 1550px) {
  .reviews-cards-container {
    width: 360px;
    height: 140px;
  }
}

@media screen and (max-width: 1000px) {
  .reviews-page-btns {
    display: none;
  }

  .reviews-page-title span {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  .reviews-cards-top-1 {
    font-size: 35px;
    line-height: 35px;
  }

  .reviews-cards-top {
    width: 360px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: start;
  }

  .reviews-cards-top-2 {
    font-size: 14px;
    font-weight: 500;
    /* line-height: 10px; */
  }

  /*  */
}

@media screen and (max-width: 500px) {
  .reviews-cards-container {
    width: 230px;
    height: 88px;
  }

  .reviews-cards-top {
    width: 230px;
  }

  .reviews-cards-top-1 {
    font-size: 28px;
    line-height: 28px;
  }

  .reviews-cards-top-2 {
    font-size: 10.325px;
    font-style: normal;
    font-weight: 500;
    line-height: 12.88px;
  }

  .reviews-cards-container {
    padding: 5px;
  }

  .reviews-cards-bottom1 {
    font-size: 8.25px;
    font-style: normal;
    font-weight: 600;
    line-height: 9.68px;
  }

  .reviews-cards-bottom2 {
    font-size: 7.438px;
    font-style: normal;
    font-weight: 600;
    line-height: 8.8px;
  }

  .reviews-cards-bottom {
    display: flex;
    gap: 5px;
    padding-left: 30px;
  }

  .reviews-cards-img {
    width: 30px;
    height: 30px;
  }
}