.profile-page{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: auto;
}
.left-container{
    margin-top: -2.2vh;
}
.circle-container{
    border: 1px solid grey;
    border-radius: 50%;
    width: 75px;
    height: 75px;

}
.with-background{
    background-image: url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/profile-empty.png);
    background-position: center;
    background-repeat: no-repeat;
}
.with-background1{
    background-image: url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/bussiness-photo.png);
    background-position: center;
    background-repeat: no-repeat;
}
.upd-img{
    width: 74px;
    height: 74px;
    border-radius: 50%;
}
.profile-con{
    width: 75px;
    height: 75px;
    position: relative;
}
.upload-btn button{
    position: absolute;
    border: none;
    z-index: 1;
    top: 45px;
    right: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-image: url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/plus.svg);
}
.profile-con1{
    width: 75px;
    height: 75px;
    position: relative;
}
.upload-btn1 button{
    position: absolute;
    border: none;
    z-index: 1;
    top: 45px;
    right: -10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-image: url(https://marathon-web-assets.s3.ap-south-1.amazonaws.com/plus.svg);
}
.circle-container1{
    border: 1px solid grey;
    border-radius: 50%;
    width: 75px;
    height: 75px;

}
.upd-img{
    width: 74px;
    height: 74px;
    border-radius: 50%;
}
.inp1{
padding-left: 10px;
}
.phone-input{
    width: 100%;
}
input[type="number"]::-webkit-inner-spin-button {
    display: none;
  }
  .inp11{
    display: flex;
  }
/* style={{ display: "flex", alignItems: "center", gap: "10px" }} */
.prev{
    display: flex;
    align-items: center;
    gap: 10px;
}