.addmember-page,
.change-pass-page,
.remove-page {
  position: absolute;
  z-index: 6;
  background-color: rgba(0, 19, 37, 0.5);
  min-width: 100%;
  height: 120vh;
  /* height: auto; */
  top: 0px;
  left: 0px;
}

.addmember-container {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  width: 41%;
  /* min-height: 38.8vh; */
  height: auto;
  position: absolute;
  top: 27%;
  left: 25%;
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin: auto;
}



.add-mem-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #101828;

  
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.1px;
}

.add-mem-description {
  display: flex;
  text-align: left;
  color: #1d2939;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 24px;
}

.add-mem-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  gap: 7px;
}

.add-mem-input {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #d0d5dd;
  background: #fff;
}

.add-mem-input input{
  width: 100%;
}

.add-mem-select button {
  background: #610bee;
  padding: 10px 16px;
  border: none;
  border-radius: 4px;
  /* height: 5.4vh; */
  /* width: 7.4vw; */
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.add-mem-email {
  padding: 5px 0px 5px 14px;
  width: 23vw;
}

.add-mem-email input {
  border: none;
  outline: none;
}

.add-mem-slct {
  box-sizing: border-box;
  padding: 10px 8px;
  width: 125px;
}

.add-mem-table {
  display: flex;
  border: 1px solid #E4E7EC;
  border-radius: 8px;
  flex-direction: column;
}

.add-mem-table-head {
  display: flex;
  gap: 14.2vw;
  background: #F2F4F7;
  height: 5.2vh;
  align-items: center;
}

.add-mem-user {
  padding-left: 16px;
  width: 11.7vw;
}

.add-mem-role {
  text-align: left;
}

.add-mem-table-details {
  display: flex;
  padding: 6px 16px;
  gap: 14.2vw;
}

.add-mem-table-photo,
.add-mem-admin {
  padding: 10px 0px 10px 0px;

}

.add-mem-table-photo {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 11.7vw;

}
/* change password  */
.change-pass-container {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  width: 32vw;
  height: auto;
  position: absolute;
  top: 20%;
  left: 32%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 10px 24px;
  margin: auto;
}

.change-pass-head {
  color: rgba(30, 44, 79, 0.92);
  
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.288px;
  margin-bottom: 16px;
}

.change-pass-input,
.change-new-pass-input,
.change-new-pass-1-input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 16px;
}

.change-new-pass-1-input {
  margin-bottom: 32px
}

.change-pass-label {
  color: rgba(30, 44, 79, 0.64);
  font-feature-settings: 'salt' on;
  font-family: Sofia Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.change-pass-input-cont {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 18px;
  border-radius: 5px;
  border: 1px solid var(--outline-50, #EDF2F7);
  background: #FFF;
}

.change-pass-input-cont input {
  border: none;
  outline: none;
}

.change-pass-btns {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 8px 10px 8px;

}

.change-pass-btns button {
  padding: 10px 20px;

}

.chnage-pass-update {
  border-radius: 5px;
  background: #6448CE;
  cursor: pointer;
  border: none;
  color: #FFF;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;

}

.change-pass-cancel {
  border-radius: 5px;
  border: 1.5px solid #7A5AF8;
  cursor: pointer;
  color: #7A5AF8;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

/* remove popup page */
.remove-container {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
    0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  width: 41.6vw;
  height: auto;
  position: absolute;
  top: 40%;
  left: 25%;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 10px 24px;
  margin: auto;
  gap: 54px;
}

.remove-head {
  display: flex;
  justify-content: space-between;
  color: #001325;
  /* Regular/20 */
  
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 140% */
}

.remove-name {
  color: #487BFD;
}

.change-remove-btns {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 40px;
}

.change-remove-cancel {
  color: #001325;
  text-align: center;
  /* Regular/16 */
  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
  cursor: pointer;
  background: white;
}

.chnage-remove-update {
  display: flex;
  gap: 8px;
  padding: 12px 16px;
  align-items: center;
  border-radius: 8px;
  background: #FF3B2F;
  color: var(--primary-white, #FFF);
  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: none;
}