@import url("https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syne:wght@700&display=swap");
body {
  margin: 0;
  font-family: "Inter" ;
  overflow-x: hidden;
}
a{
  text-decoration: none;
  color: #000; 
}
textarea {
  font-family: "Inter" ;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
tr:hover{
  cursor: pointer;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%
    ),
    #5b89ff;
}
span,h3,h5,p,ul{
  margin: 0px;
}
ul{
  padding-left: 16px;
}
button{
  cursor: pointer;
}
:root {
  --pop-up-bg: 0px 9.066666603088379px 9.066666603088379px -4.5333333015441895px rgba(16, 24, 40, 0.03),
  0px 22.66666603088379px 27.200000762939453px -4.5333333015441895px rgba(16, 24, 40, 0.08);
  --active-btn-bg: #610BEE;
  --non-active-btn-bg: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #610BEE;
}