

.create-tkt {
  top: 18%; 
  /* min-height: 120vh; */
  height: 98vh;
  
  left: 40%; 
  top: 0%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  align-items: center;
}
.tkt {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 23vw;
}
.tkt-btn {
  display: flex;
  /* justify-content: space-between; */
  /* gap: 12px; */
  padding: 10px 20px ;
  align-items: center;
  border: none;
  border-radius: 4px;
  background: #610bee;
  color: white;
  
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}

.tkt-btn:hover {
  cursor: pointer !important;
}

.template-container {

  position: absolute;
  z-index: 4;
  background-color: rgba(0, 19, 37, 0.5);
  width: 100%;
  height: 120vh;
  /* height: auto; */
  top: 0px;
}
.tick-tmpt {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 18%;
  left: 35%;
  gap: 32px;
  border-radius: 12px;
  border: 1px solid #edf2f7;
  box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
  
  background: white;
  padding: 24px;
  width: 30vw;
  height: auto;
}
.create-template-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
  letter-spacing: -0.3px;
}
.template-input-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.test-template,
.test-assign {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.template-btns {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.start-config,
.start-cancel {
  padding: 10px 20px;
  color: white;
   background: linear-gradient( 0deg, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.6) 100% ), #610bee;; 
  /* background: #3212b3; */
  border-radius: 5px;
  max-width: 160px;
  height: 44px;
  border: 1px solid #610BEE;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.14px;
}
.start-cancel{
  background: white;
  border: 1px solid #610BEE;
  cursor: pointer;
  color: #610BEE;
}


.start-config:hover {
  cursor: pointer;
  color: white;
  background: #610BEE;


}

.test-template input{
  box-sizing: border-box;
  padding: 10px 16px;
  height: 6vh;
  outline: none;
  border: 2px solid #edf2f7;
  border-radius: 4px;
}
.assign-select{
  outline: none;
}
.template-error-msg{
  color: red;
  font-size: 10px;
  display: flex;
  gap: 10px;
  align-items: center;

}
.templateTopNav{
  position: fixed;
  top: 0px;
  width: 100%;
  height: 9vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  background: white;
  border-bottom: 1px solid #edf2f7;
  /* padding: 12px 64px; */
}
.template-logo{
  margin-left: 68px;
  display: flex;
  align-items: center;
  gap: 10px;

}
.template-logo img{
  height: 30px;
}
.template-flex{
  display: flex;
  gap: 13px;
  align-items: center;
  margin-right: 64px;
}
.btn-sv-checked{
  background-color: #610BEE;
  color: white;
}