.part-view-page {
    margin-top: 80px;
    width: 100%;
    box-sizing: border-box;
    padding: 24px 90px 24px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 88vh;
    overflow: auto;
    overflow-x: hidden;

}

.part-view-title {
    display: flex;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;

    /* 142.857% */
}

.part-view-title img {
    cursor: pointer;
}

.part-view-cont {
    display: flex;
    align-items: flex-start;
    gap: 28px;
    width: 100%;
}

.part-view-left {
    display: flex;
    flex-direction: column;
    width: 70%;
    box-sizing: border-box;
}

.part-view-left-title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-bottom: 20px;
}

.part-view-left-title span:nth-child(1) {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

/* .part-view-left-title span:nth-child(2) {
    color: #001325;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.288px;
} */

.part-view-desc {
    color: #001325;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 24px;
    /* 133.333% */
}

.part-view-right {
    border-radius: 8px;
    border: 1px solid #EDF2F7;
    background: #FFF;
    width: 40%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 17px;
    /* height: 25vh; */

    padding: 20px;
    box-sizing: border-box;
}

.part-view-inputs {
    display: flex;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
}

.tab-part-view-inputs {
    display: flex;
    align-items: center;
    gap: 5px;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    margin: 2%;
}

.part-view-owner {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 10px 16px;
    width: 220px;
    box-sizing: border-box;

}

.part-view-owner span {
    width: 100%;
}

.part-view-inputs span {
    width: 100px;

}

.part-view-inputs input {
    width: 210px;
    box-sizing: border-box;
    padding: 10px 16px;
    border: none;
}

.part-view-photo {
    width: 28px;
    height: 28px;
    border-radius: 50%;
}

.bom-dropdown {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.192px;
    border-radius: 8px;
    border: 1px solid #EDF2F7;
    background: #EDF2F7;
    margin-top: 50px;
}

.part-bom-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    gap: 10px;
    background-image: url('https://marathon-web-assets.s3.ap-south-1.amazonaws.com/org-background.svg');
    background-repeat: repeat;
    background-size: auto;
}

.part-bom-partname-boxes,
.part-bom-partname {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid blue;
    height: 30px;
    padding: 12px 16px;
    border-radius: 10px;
}

.part-bom-partname-vertical-line {
    width: 1px;
    height: 30px;
    background: black;
}

.part-bom-partname-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.rotate {
    transform: rotate(-180deg);
    transition: transform 0.5s ease;
    /* Smooth transition effect */
}

.non-rotate {
    transform: rotate(0deg);
    transition: transform 0.5s ease;
}

.part-title-view {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    font-size: 24px;
}

.part-title-view span {
    max-width: 90%;
    word-break: break-all;
}

.part-title-view img {
    display: none;
}

.part-title-view textarea {
    width: 100%;
    border: 1px solid #EDF2F7;
    font-size: 24px;

}

.part-title-view:hover img {
    display: flex;
    cursor: pointer;
}

.switch.on .bom-on {
    display: block !important;
    /* Show BOM tree view */
}

.switch.on .excel-on {
    display: none !important;
    /* Hide BOM excel view */
}

.switch.off .excel-on {
    display: block !important;
    /* Show BOM excel view */
}

.switch.off .bom-on {
    display: none !important;
    /* Hide BOM tree view */
}

.toggle-mode-views {
    display: flex;
    align-items: center;
    gap: 10px;

}





@media screen and (max-width: 1000px){
    .part-view-cont{
        flex-direction: column;
    }
    .part-view-left{
        width: 100%;
    }
    .part-view-right{
        width: 100%;
    }
}

@media screen and (max-width: 575px){
.part-view-page{
    padding: 24px 24px 24px 24px;
}
}