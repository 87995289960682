.excel-parts {
    max-width: 100%;
    
    overflow-x: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ebom-hierarchy{
    position: relative;
    background-image: url('https://marathon-web-assets.s3.ap-south-1.amazonaws.com/org-background.svg');
}

.excel-parts::-webkit-scrollbar,.excel-custom-scrollbar::-webkit-scrollbar {
    height: 5px; 
}


.excel-parts::-webkit-scrollbar-thumb,.excel-custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: darkgrey; 
    border-radius: 5px; 
}


.excel-parts::-webkit-scrollbar-track,.excel-custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px; 
}

/* Add this CSS to your stylesheet */
/* .react-spreadsheet {
    pointer-events: none; 
} */
.excell-download{
    display: flex;
    align-items: center;
    gap: 10px;
    background: #610bee;
    color: white;
    font-size: 14px;
    padding: 10px 16px;
    border-radius: 8px;
    border: none;
    margin-bottom: 20px;
}
.excell-download img{
    filter: invert(100);
}
.excell-button-download{
    width: 100%;
    display: flex;
    justify-content: right;
}