.form-page {
  position: absolute;
  z-index: 6;
  background-color: rgba(0, 19, 37, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150vh;
  height: auto;
  top: 0px;
}

.dept-ticket-home {
  width: 52vw;
  border-radius: 12px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
  padding: 24px;
  margin-bottom: 50px;
  /* height: 100vh; */
}

.supply-ticket-home {
  width: 52vw;
  border-radius: 12px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08);
  padding: 24px;
  margin: auto;
  height: 90vh;
  margin-bottom: 20px;
}

.ec-form-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 19, 37, 0.5);
  position: fixed;
  z-index: 6;
  height: 100vh;

}

.ec-part-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 19, 37, 0.5);
  position: absolute;
  z-index: 6;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.ec-ticket-home {
  width: 65%;
  /* margin-top: 80px; */
  border-radius: 12px;
  border: 1px solid #EDF2F7;
  background: #FFF;
  padding: 24px;
  /* height: 83vh; */


}

.ex-ticket-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

}

.ec-title {
  color: #001325;
  /* Medium/20 */

  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: -0.3px;
  /* margin-bottom: 100px; */

}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 20px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  color: #001325;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  box-sizing: border-box;
  letter-spacing: -0.14px;
}

.workspace-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 20px;
  width: 100%;
  color: #001325;
  box-sizing: border-box;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
  box-sizing: border-box;
  letter-spacing: -0.14px;
}

.full-width-item {
  grid-column: span 2;
}

.full-width-input {
  width: 100%;
}

.grid-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.type-texts {
  width: 24vw;
  padding: 10px 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.member-dropdown {
  width: 24vw;
}

.type-texts input,
.type-texts textarea {
  border: none;
  outline: none;
  width: 100%;
  /* resize: none; */
}


.full-width-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filedropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  border-radius: 4px;
  border: 1px dashed #c7cacd;
  background: #FFF;
  position: relative;
  width: 100%;
}

.filedropzone p {
  color: #001325;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.filedropzone span {
  color: #610BEE;
  cursor: pointer;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.uploaded-files {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 30px;



}

.uploaded-file-part {
  display: grid;
  grid-template-columns: auto auto;
}

.uploaded-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

.file-details {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 20vw;
  gap: 12px;
  padding: 6px;
  border: 1px solid #c7cacd;
  border-radius: 4px;

}


.upld-file-details {
  width: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.upld-file-details span {
  width: 15vw;
  color: #24292f;

  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.uploaded-file button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  font-size: 12px;
}

.loading-line {
  height: 2px;
  width: 100%;
  background-color: #007bff;
  animation: loading 2s infinite;
}

@keyframes loading {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

.depart-form-btns {
  width: 100%;
  padding-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  background: white;
  justify-content: flex-end;
}

.dept-form-submit {
  padding: 10px 20px;
  border-radius: 5px;
  background: #610BEE;
  border: none;
  color: #FFF;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
  cursor: pointer;
}

.dept-form-submit.enabled-button {
  background: #610BEE;
}

.dept-form-cancel {
  cursor: pointer;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1.5px solid #7A5AF8;
  color: #7A5AF8;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.you-text {
  border-radius: 4px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #5B89FF;
  padding: 2px 4px;
}

.selected-mem-list {
  color: #5e5f5f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}

.part-num {
  text-align: left;
  width: 350px;


}

.part-num,
.part-tit,
.part-quan {
  color: #001325;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: 20px;
  letter-spacing: -0.14px;
}

.part-tit {
  text-align: left;
  width: 450px;
}

.part-data {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid red;
}

.department-table-part-data {
  width: 100%;
}

.department-table-part-data th,
td {
  padding: 18px 12px;
}

.department-table-part-data th {
  border-bottom: 2px solid #edf2f7;

}

.department-table-part-data:hover th {
  background: white;
}

.part-div-infinite {
  height: 60vh;
  overflow: auto;
}

.part-numbers-views-input {
  width: 80px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.data-not-found-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92%;
  /* border: 1px solid red; */
  overflow: hidden;
  height: 40vh;
}

.data-not-found-img img {
  width: 250px;
  height: 250px;
}

.add-upload-btn {
  display: flex;
  align-items: center; 
  padding: 8px 12px;
  border-radius: 4px;
  background: #FFF;
  color: #610bee;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.25);
  border: none;
}

.upload-type {
  display: flex;
  flex-direction: column;
  /* width: fit-content; */
  align-items: flex-start;
  justify-content: start;
  border: 1px solid #610bee;
  border-radius: 4px;
  width: 250px;
  position: absolute;
  background: white;
  left: 100%;
  top: 0%;
  overflow: hidden;
  z-index: 2;
  
}

.upload-type-btn {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  color: black;
  

}

.upload-type-btn:hover {
  background:  linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #5B89FF;
}



