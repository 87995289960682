.dashboard-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
    padding: 90px 0px 20px 0px;
    box-sizing: border-box;
    background-image: url('https://marathon-web-assets.s3.ap-south-1.amazonaws.com/dashboard-bg.webp');
    background-position: center;
}

.dashboard-top-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color:  #001325;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.288px;
}
.dashboard-top-cont span{
    opacity: 0.7;
}
.dashboard-items-cont {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-radius: 12px;
    gap: 8px;
    overflow: hidden;
    background: white;
}

.dashboard-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 12px;
    width: 180px;
    height: 100px;
    box-sizing: border-box;
    background: white;
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.192px;
}

.dashboard-items:hover,
.dashboard-items.active {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
}

.dashboard-items img {
    width: 40px;
    height: 40px;

}

.dashboard-bottom-cont {
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    opacity: 0.5;
}