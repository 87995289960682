

/* height checks required*/
.viewrole-page {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0%;
    top: 0%;
    background-color: white;
    width: 26vw;
    z-index: 1;
    
    justify-content: space-between;
    padding-left: 1.7vw;
    padding-right: 1.7vw;
    margin-bottom: 4.8vh;
    border-left: 1px solid #edf2f7;
    padding-top: 9.7vh;
    min-height: 93vh;
    height: auto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.cancel-btn:hover {
    cursor: pointer;
}

.viewrole-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vh;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.3px;
}

.viewrole-photo-cont {
    padding: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.viewrole-photo {
    border-radius: 50%;
    width: 200px;
    height: 200px;
}

.viewrole-details {
    display: flex;
    flex-direction: column;
    gap: 4.8vh;
}

.viewrole-name {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: #001325;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

}


.viewrole-address,
.viewrole-group,
.viewrole-contact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
    align-items: flex-start;
}

.viewrole-loc,
.viewrole-time,
.viewrole-group-detail {
    display: flex;
    gap: 8px;
    align-items: center;
}

.viewrole-email-label,
.viewrole-phone-label {
    display: flex;
    gap: 8px;
    align-items: center;
}

.viewrole-email-detail,
.viewrole-phone-detail {
    padding-left: 32px;
}

.viewrole-bottom {
    padding-top: 24px;
    padding-bottom: 24px;
}

.viewrole-bottom button {
    width: 8.2vw;
    height: 6vh;
    padding: 10px 20px;
    border-radius: 4px;

    background: #610BEE;
    border: none;
    color: white;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    cursor: pointer;

}

.viewrole-bottom button:hover {
    background: #9770d4;
}