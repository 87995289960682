.usage-page {
    padding: 32px;
    position: relative;
    width: 53vw;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.usage-page-top-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

/* .usage-page-add-img {
    -webkit-filter: invert(0%); 
    filter: invert(0%);
} */

.left-btns {
    display: flex;
    gap: 8px;
}

.left-btns button {
    cursor: pointer;
    padding: 12px 24px;
    border-radius: 5px;
    border: 1px solid #080808;
    background: #FFF;
    color: #001325;
    text-align: center;
    /* Medium/16 */
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 150% */
    letter-spacing: -0.192px;
}

.left-btns .UsersSelected {
    cursor: pointer;
    background: #FF7A7A;
    border: none;
    color: white;

}
.UsersSelected{
    background: red;
}
.usage-page-add {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 20x 8px 18px;
    border-radius: 4px;
    background: #610BEE;
    border: none;
    box-sizing: border-box;
    height: 5.4vh;
    width: 10.4vw;
    text-align: center;
    color: #FFF;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}
.usage-page-add-img {
    filter: invert(1);
    
}
.member-page-home{
    width: 100%;
}
.member-page{
    display: flex;
    flex-direction: column;
width: 100%;

}
.member-details{
    display: flex;
    align-items: center;
    background:  #FFF;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
    width: 52vw;
}
.member-details:hover{
    background:  linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
}
.member-profile{
    display: flex;
    align-items: center;
    /* gap: 12px; */
    width: 12.2vw;
    padding: 13px 25px 13px 24px;
    color:  #1E2C4FEB;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    box-sizing: border-box;
}
.member-email{
    display: flex;
    align-items: center;
    /* gap: 12px; */
    width: 20.3vw;
    padding: 13px 25px 13px 24px;
    color:  #1E2C4FEB;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    box-sizing: border-box;
}
.select-admins{
    width: 10vw;
    color:  #1E2C4FEB;
    
}
.deactivate-admins{
    width: 12.3vw;
    color:  #001325;
/* Regular/14 */

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
}
.selecttags__control{
    border: none;
}
.dot-menu{
    width: 6.5vh;
    position: relative;
    padding: 13px 56px 13px 24px;
}
.member-delete-btn{
    position: absolute;
    z-index: 5;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 6px 30px 6px 12px;
    border-radius: 8px;
    border: 1px solid  #EDF2F7;
    background:  #FFF;
    box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
}
.invited-delete-btn{
    position: absolute;
    z-index: 5;
    display: flex;
   
    align-items: center;
    padding: 6px 27px 6px 12px;
    width: 11.5vw;
    border-radius: 8px;
    border: 1px solid  #EDF2F7;
    background:  #FFF;
    box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
}
.deactivate-delete-btn{
    position: absolute;
    z-index: 5;
    display: flex;
    align-items: center;
    padding: 6px 34px 6px 12px;
    border-radius: 8px;
    border: 1px solid  #EDF2F7;
    background:  #FFF;
    box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
}