.set-up-pass {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: space-between;
  font-family: "Inter";
}

.left-container {
  padding-top: 12.1vh;
  width: 70%;
  display: flex;
  flex-direction: column;
  padding-left: 3.6vw;
  gap: 25px;
  margin-bottom: 24.3vh;
}

.prev {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
}
.prev-text{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
}
.p1,
.p2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inp1,
.inp2 {
  border: 1px solid #e6e4f0;
  display: flex;
  justify-content: space-between;
  width: 60%;
  border-radius: 5px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  height: 50px;
}

.inp1 input,
.inp2 input {
  font-size: 16px;
  border: none;
  width: 90%;
  outline: none;
  border-radius: 5px 0px 0px 5px;
  height: 90%;
}

.p1 label,
.p2 label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.inp1 button,
.inp2 button {
  border: none;
  background: none;
}

.nxt-btn {
  display: flex;
  width: 60%;
  height: 35px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: white;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: none;
  background: #610bee;
  border-radius: 4px;
}

.nxt-btn:hover {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6)),
    #610bee;
  cursor: pointer;
}

.right-container {
  display: flex;
  width: 25%;

  min-height: 100vh;

}

.right-container img {
  width: 100%;
  min-height: 100vh;
}

.track {
  display: flex;
  margin-top: 38vh;
  margin-bottom: 12.1vh;
}

.track img {
  width: 50%;
  padding-bottom: 15.2vh;
}

.inp1 {
  position: relative;
}

/* .inp1 input {
    border: 1px solid #ccc;
  } */

.inp1.error {
  border: 1px solid red;
}

.inp1.error input,
.inp2.error input {
  border: none;
  height: auto;
}

.inp2 {
  position: relative;
}

/* .inp2 input {
    border: 1px solid #ccc;
  } */

.inp2.error {
  border: 1px solid red;
}

/* .p2{
    display: flex;
    flex-direction: column;
} */
/* .inp2{
    border: 1px solid grey;
    display: flex;
    justify-content: space-between;
    width: 480px;
    border-radius: 5px;
} */
/* .inp2 input{
    border: none;
    width: 90%;
    outline: none;
    border-radius: 5px 0px 0px 5px;
    height: 90%;
} */
/* .inp2 button{
    border: none;
    background: none;
} */