.company-details-bottom {
    display: flex;
    gap: 48px;
}

.company-details-left {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.company-address-div {
    display: flex;
    flex-direction: column;
    gap: 24px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.add-alternate-address-btn{
    border-radius: 4px;
background:  linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #610BEE;
padding: 10px 20px;
color: #610bee;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 142.857% */
letter-spacing: -0.14px;
border: none;
height: 40px;
width: 186px;
}