.template-home {
    width: 100%;
    margin-top: 100px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.template-page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    height: 84vh;
    /* border: 5px solid red; */
    /* overflow-y: scroll; */
}

.template-home::-webkit-scrollbar {
    visibility: hidden;
}

.template-top-bar {
    display: flex;
    justify-content: space-between;
    width: 95%;
}

.template-top-right {
    width: 75%;
    /* border: 1px solid black; */
    display: flex;
    /* gap: 20px; */
    justify-content: space-between;
}

.template-back-icon {
    display: flex;
    align-items: center;
    gap: 16px;
}

.template-edit-btns {
    display: flex;
    align-items: center;
    gap: 10px;
}

.template-edit-btns button {
    border: 1px solid #610bee;
    padding: 5px 10px;
    border-radius: 4px;
}

.template-edit-input {
    border-radius: 4px;
    padding: 5px;
    font-size: 16px;
    width: 100px;
}

.template-title-div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.template-back-icon img:hover {
    cursor: pointer;
}

.template-title {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.288px;
    color: #001325;
}

.template-edit:hover {
    cursor: pointer;
}

.template-title-ver-line {
    border: 1px solid #EDF2F7;
    height: 20px;
    width: 0px;
}

.changes-btn,
.no-changes-btn,
.changes-btn-non-active {
    padding: 10px 20px;
    width: 73px;
    height: 40px;
    border-radius: 4px;
    border: none;
    color: white;
    background: #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
    cursor: pointer;
}

.no-changes-btn {

    /* color: white; */
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #610BEE;
}

.template-field-details {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    gap: auto;
    width: 100%;
} 

.template-field-left {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* border: 1px solid black; */
    gap: 20px;
    background: #F6F6F6;
    height: 73vh;
    width: 30%;
    overflow-y: scroll;
    /* padding-bottom: 50px; */

}

.template-field-left::-webkit-scrollbar {
    display: none;
    display: none;
}

.template-field-left-top {
    display: flex;
    margin-bottom: 20px;
}

.template-elements {
    display: flex;
    flex-direction: column;
    text-align: left;
    /* border: 1px solid black; */
}

.template-field {
    cursor: grab;
}

.template-field:active {
    cursor: grabbing;
}

.template-form-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.3px;
    color: #001325;
}

.template-form-subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: #001325;
}

.template-field-left-bottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.template-field-text {
    border: 1px solid black;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    background: white;
    border: 1px solid #EDF2F7;
    width: auto;
}

.template-field-text span {
    width: 16vw;

}

.template-mandatory {
    display: flex;
    justify-content: space-between;
}

.dropped-default-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.dropped-default-list-title {
    color: #001325;
    /* Medium/14 */

    font-size: 14px;
    font-style: normal;
    font-weight: 650;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}

.dropped-default-list-desc {
    color: #5a5959;
    /* Regular/12 */

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
}

.dropped-default-list-array {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.template-field-right {
    display: flex;
    width: 80%;
    box-sizing: border-box;
    padding: 24px;
    flex-direction: column;
    gap: 24px;
    height: 79vh;
    height: 79vh;
    overflow-y: scroll;
    /* border: 1px solid green; */
}

.before-dropping {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 10px;

}

.before-dropping span {
    color: rgba(8, 7, 40, 0.64);
    gap: 15px;
    /* Regular/16 */

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    /* 150% */
    text-align: center;
    padding-right: 25px;
}

.template-mandatory-outbox,
.template-custom-outbox {
    display: flex;
    flex-direction: column;
    
    border-radius: 12px;
    border: 1px solid #EDF2F7;
    padding: 20px 24px;
    gap: 50px;
}

.template-mandatory-container,
.template-custom-container {
    display: flex;
    justify-content: space-between;

}

.template-mandatory-content,
.template-custom-content {
    display: flex;
    flex-direction: column;
}

.dropping-mandatory,
.dropping-custom {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* min-height: 61.2vh; */
    height: auto;
    /* overflow-y: auto; */
}
.dropping-custom{
    min-height: 50vh;
    /* max-height: 50vh; */
}
.mandatory-heading,
.custom-heading {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 140% */
    letter-spacing: -0.3px;
}

.mandatory-sub-heading,
.custom-sub-heading {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.template-draged-items {
    position: relative;
    border-radius: 8px;
    border: 1px solid #EDF2F7;
    background: #FFF;
    margin-top: 10px;
    display: flex;
    /* justify-content: space-between; */
    gap: 8px;
    padding: 4px;
    align-items: center;
}

.template-draged-items:hover {
    cursor: move;
}

/* .template-draged-items:hover{
    border: 1px solid #FF7A7A;
} */
/* .template-draged-items:hover .template-drag-box{

    background:  linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FF7A7A;
} */

.template-item-details {

    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    width: 100%;
}

.template-draged-items.active,
.template-draged-items.custom-active {
    border: 1px solid #FF7A7A;
}

.template-draged-items.selected {
    border: 1px solid #FF7A7A;
}

.template-edit-fields {
    display: none;
}

.template-draged-items.active .template-edit-fields.show,
.template-draged-items.custom-active .template-edit-fields.custom-show {
    position: absolute;
    display: flex;
    padding: 6px 12px;
    gap: 16px;
    top: -15px;
    left: 85%;
    border-radius: 6px;
    border: 1px solid #FF7A7A;
    background: #FFF;

}

.template-draged-items img:hover {
    cursor: pointer;
}

.template-item-details span {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}

.template-item-details-input {
    display: flex;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #EDF2F7;
    height: 40px;
    width: 100%;
    box-sizing: border-box;
}

.template-item-details-input input {
    width: 100%;
    border: none;
    outline: none;
}

.template-drag-box {
    width: 23px;
    height: 73px;
    /* border: 1px solid black; */
    padding: 6px;
    display: flex;
    gap: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px 0px 0px 4px;

}

.template-drag-box:hover {
    cursor: grab;
}

.template-drag-box-right,
.template-drag-box-left {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.dots {
    width: 3.5px;
    height: 3.5px;
    border-radius: 50%;
    background: black;
}

.template-draged-items.active .template-drag-box {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.80) 100%), #FF7A7A;
    /* Change the background color to yellow when active */
}

.custom-drop-img {
    transform: rotate(180deg);
}

.template-draged-items {
    transition: transform 0.3s ease-in-out;
}

.template-draged-items.dragging {
    opacity: 0.5;
}

.version-number-text {
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: 600; */
    width: 9.7vw;
    font-size: 18px;
    /* border: 1px solid black; */
}

.version-number-text span {
    color: #610BEE;
    font-weight: 600;
    font-size: 20px;
    /* background: #777879; */
}

.version-number-text-hidden {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9.7vw;

}

.template-save-btn-1 {
    width: 9.7vw;

}

.template-save-btn-1 button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.changes-btn-non-active {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.60) 100%), #610BEE;
}

.version-numer-text {
    color: #610BEE;
    font-size: 18px;
}

.horizontal-line {
    width: 100%;
    border-top: 2px solid transparent;
    /* Default border color when not dragging over */
    /* margin-top: 5px;
    margin-bottom: 5px; */
    transition: border 0.3s ease;
    visibility: hidden;
}

.dragging-over-class {
    border-bottom: 1ps solid #610bee;
    visibility: visible;
    border-color: #610bee;
    box-shadow: 0px 2px 5px rgba(97, 11, 238, 0.5);
}




/* po-box in custom fileds */
.po-template-field-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border-radius: 8px;
    background: #F6F6F6;
    box-sizing: border-box;
}
.po-template-custom-box{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 88px;
    width: 100%;
}

.po-template-tandc-box{
    display: flex;
    align-items: center;
    gap: 8px;
    height: 150px;
    width: 100%;
}

.po-template-custom-inputs{
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
    padding: 12px;
    box-sizing: border-box;
    background: white;
    height: 88px;
    border-radius: 0px 8px 8px 0px;

}
.po-template-custom-editor{
    display: flex;
    align-items: flex-start;
    width: 100%;
    /* padding: 12px; */
    box-sizing: border-box;
    background: white;
    height: 150px;
    border-radius: 0px 8px 8px 0px;
    overflow: auto;
}
.po-template-custom-fieldnames{
    padding: 12px 16px;
    border: 1px solid #edf2f7;
    border-radius: 8px;
   
}