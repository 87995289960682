
.settings-page{
    width: 100%;
    height: 100vh;
    /* border: 1px solid #ff0000; */
    overflow-y: auto;
}
.settings-cont{
    width: 100%;
}
.setting-home {
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-top: 32px;
    padding-bottom: 12px;
    color: #001325;
    margin-top: 11.3vh;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.288px;
    gap: 10px;
}

.setting-navs {
    display: flex;
    gap: 16px;
    padding-left: 32px;
    border-bottom: 1px solid #EDF2F7;
}

.users,
.notification {
    padding: 10px 16px;
}

.privacy {
    padding: 10px 16px;
}

.general {
    padding: 10px
}

.general:hover,
.users:hover,
.notification:hover,
.privacy:hover {
    cursor: pointer;
    box-shadow: 0px -3px 0px 0px #FF7A7A inset;
}

.settingActive {
    cursor: pointer;
    box-shadow: 0px -3px 0px 0px #FF7A7A inset;
}