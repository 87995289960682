.general-page {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 32px;
    position: relative;
}

.general-profiels {
    display: flex;
    align-items: center;
    gap: 30px;
}

.general-upload {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #EDF2F7;
    margin-top: 5px;
}

.general-upload {
    position: relative;
}

.general-upload-con button {
    cursor: pointer;
    position: absolute;
    top: 60%;
    left: 80%;
    background-color: white;
    border-radius: 50%;
    border: 2px solid #EDF2F7;
    padding: 6px;

}

.general-company-name {
    display: flex;
    flex-direction: column;
    gap: 6px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.general-company-name-input {
    
    display: flex;
    gap: 16px;
    align-items: center;
}

.general-company-name-input input {
    height: 48px;
    box-sizing: border-box;
    width: 20.8vw;
    border-radius: 5px;
    border: 1px solid #EDF2F7;
    background:  #F6F6F6;
    padding-left: 18px;
}
.general-company-name-input textarea {
    height: 100px;
    box-sizing: border-box;
    width: 480px;
    resize: none;
    border-radius: 5px;
    border: 1px solid #EDF2F7;
    background:  #F6F6F6;
    padding: 12px 18px;
}
.general-company-name-input button {
    cursor: pointer;
    height: 44px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #080808;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    border-radius: 4px;
    border: 1.5px solid #080808;
}




@media (max-width: 768px) {
    .container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 80%;
    max-width: 800px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.editPhoto-page {
    position: absolute;
    z-index: 6;
    background-color: rgba(0, 19, 37, 0.5);
    min-width: 100%;
    height: 100vh;
    /* height: auto; */
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editPhoto-page-div {
    background: white;
    width: 50%;
    border-radius: 8px;

}

.edit-profile-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px 10px 10px;
    color: #001325;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
}

.close-icon-btn {

    border-radius: 50%;
    padding: 5px;
}

.close-icon-btn:hover {
    cursor: pointer;
    background-color: rgba(177, 176, 176, 0.5);
    ;
}

.avtar-edit-box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
}

.rotate-btns {
    display: flex;
    width: 100%;
    /* box-sizing: border-box;
    padding-right: 50px; */
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.rotate-btns button {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background-color: #610BEE;
    border: none;

}

.rotate-btns img {
    filter: invert(1);
}

.edit-save-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    /* padding-right: 50px; */

}

.edit-save-btn button {
    background-color: #610BEE;
    border-radius: 20px;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.zoom-edit,
.rotate-edit {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.edit-options {
    display: flex;
    justify-content: space-around;
    align-items: center;
}