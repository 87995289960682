@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.org-hierarchy {
  background-image: url('https://marathon-web-assets.s3.ap-south-1.amazonaws.com/org-background.svg');
  background-repeat: repeat;
  background-size: auto;
  width: auto;
  height: auto;
  margin-left: 3px;
}


.rounded-name-d3{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  /* margin-right: 10px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background: #610bee;
  color: white;
  font-weight: 500;
  text-align: center;
}
.image-container-d3 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.rounded-image-d3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rotated-img {
  transform: rotate(45deg);
}

.zoom-buttons {
  position: fixed;
  bottom: 25px;
  right: 50px;
  display: flex;
  gap: 21px;
  padding: 12px;
  border-radius: 37px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.zoom-buttons button {
  border: none;
  background: white;
  color: #001325;
}

.zoom-buttons button:hover {
  cursor: pointer;
}

.menu-box,
.menu-box2 {
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
  position: static;
}

.menu-box button {
  border: none;
  background-color: white;
  width: 100%;
  height: 4.8vh;
  border-radius: 8px 8px 8px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 0px 16px 0px 16px;
}

.menu-box2 button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  border: none;
  background-color: white;
  width: 100%;
  height: 4.8vh;
  border-radius: 8px 8px 8px 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 0px 16px 0px 16px;
}

.menu-horizon-line {
  width: 90%;
  margin-left: 6px;
  border: 1px solid #C4C9CC;
}

.rounded-image-d3 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rotated-img {
  transform: rotate(45deg);
}

.zoom-buttons {
  position: fixed;
  bottom: 25px;
  right: 50px;
  display: flex;
  gap: 21px;
  padding: 12px;
  border-radius: 37px;
  border: 1px solid #EDF2F7;
  background: #FFF;
}

.zoom-buttons button {
  border: none;
  background: white;
  color: #001325;
}

.zoom-buttons button:hover {
  cursor: pointer;
}

.menu-box,
.menu-box2 {
  width: 10vw;
  height: auto;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
  background-color: white;
  /* border: 1.5px solid #adaeae; */
}

.menu-box text {
  border: none;
  width: 100%;
  padding: 5px;
  border-radius: 8px 8px 8px 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 5px;
}

.menu-box2 text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background-color: white;
  width: 100%;
  border-radius: 8px 8px 8px 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 8px 16px;
}

.menu-horizon-line {
  width: 90%;
  margin-left: 6px;
  border: 1px solid #C4C9CC;
}

.menu-box text:hover,
.menu-box2 text:hover {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #62ECFF;
  box-shadow: 0px 2px 4px 0px rgba(72, 123, 253, 0.10);
  cursor: pointer;
}
.collab-btn-in-org{
  position: absolute;
  top: 70px;
  right: 50px;
  z-index: 5;

}