.security-page {
  padding: 30px 63px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.security-container {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%),
    #610bee;
  display: flex;
  padding: 100px 50px;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
  gap: 50px;
  /* height: 110vh; */
  width: 100%;
  border-radius: 30px;
}

.security-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  gap: 6px;
  text-align: left;
}

.security-head {
  color: #fff;
  font-family: Syne;
  font-size: 52.313px;
  font-style: normal;
  font-weight: 600;
  line-height: 63.36px;
  /* 121.118% */
  letter-spacing: -0.48px;
}

.security-desc {
  color: rgba(255, 255, 255, 0.8);
  padding-right: 6.5vw;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  /* 160% */
  letter-spacing: -0.5px;
}

.security-btn {
  display: flex;
  align-items: flex-start;
  justify-content: left;
  /* Medium/16 */
}

.security-btn button {
  color: #fff;
  cursor: pointer;
  width: 145px;
  height: 44px;
  /* padding: 1.7vh 2vw; */
  background: inherit;
  border: 1.5px solid #fff;
  border-radius: 4px;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: -0.192px;
}

.security-points {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  gap: 40px;
}

.security-internal-points img {
  width: 40px;
  height: 41px;
}

.security-internal-points span {
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  /* 150% */
}

.security-internal-points {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: flex-start;
}

.security-points-1 {
  display: flex;
  flex-direction: column;
  gap: 25px;

}

@media screen and (max-width:1000px){
  .security-container{
    padding: 48px;
    flex-direction: column;
}
/* .security-points{
  display: flex;
  flex-direction: row;
} */
}
@media screen and (max-width:600px) {
  .security-page{
    padding: 24px;
  }
  .security-container {
    
    padding: 24px;
    gap: 45px;
  }

  .security-head {
    font-size: 24px;
    line-height: 32px;
  }

  .security-desc {
    font-size: 12px;
    line-height: 16px;
  }

  .security-internal-points span {
    font-size: 16px;
    line-height: 20px;
  }

  .security-btn button {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}