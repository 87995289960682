.capabilities-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 64px;
  gap: 64px;
}

.capabilities {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 13px;
  /* height: 215px; */
  box-sizing: border-box;
  padding: 64px;
}

.capabilities,
.capability-ticket-template,
.capability-assigne,
.capability-teams,
.capability-org {
  width: 100%;
  box-sizing: border-box;
  /* border: 2px solid red; */
}

.capabilities-head {
  color: #610bee;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  /* 112.5% */
  text-transform: uppercase;
}

.capabilities-desc {
  color: #001325;
  text-align: center;
  font-family: Syne;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
  /* 116.667% */
}

.capability-ticket-template,
.capability-assigne,
.capability-teams,
.capability-org {
  display: flex;
  border-radius: 32px;
  height: 584px;
}

.capability-ticket-template {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%),
    #610bee;
}

.capability-assigne {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%),
    #ff9845;
}

.capability-teams {
  background: linear-gradient(0deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(255, 255, 255, 0.9) 100%),
    #ff7a7a;
}

.capability-org {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #3585E4;
}

.capability-ticket-template-img,
.capability-assigne-img,
.capability-org-img {
  width: 50%;
  height: 100%;
}

.capability-assigne-img img {
  width: 100%;
  height: 100%;
}

.capability-teams-img img {
  width: 51vw;
  height: 427px;
}

.capability-org-img img {
  width: 659.5px;
  height: 492px;

}

.capability-org-img {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-right: 150px;
  box-sizing: border-box;
}

.capability-ticket-template-img img {
  width: 100%;
  height: 100%;
}

.capability-ticket-template-head,
.capability-assigne-head,
.capability-teams-head,
.capability-org-head {
  color: #001325;
  font-family: Syne;
  padding-right: 7vw;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 50.4px;
}

.capability-assigne-head {
  color: #03073d;
  padding-right: 4vw;
  font-family: Syne;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 50.4px;
}

.capability-teams-head {
  color: #001325;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: Syne;
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 50.4px;
  /* 120% */
}

.capability-assigne-content,
.capability-teams-content,
.capability-ticket-template-content,
.capability-org-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 17px;
  padding-left: 64px;
  box-sizing: border-box;
  justify-content: center;
  /* align-items: center; */
}

.capability-assigne-desc,
.capability-teams-desc,
.capability-ticket-template-desc,
.capability-org-desc {
  color: rgba(0, 19, 37, 1);
  padding-right: 14.6vw;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.3px;
}

.capability-assigne-desc {
  color: #001325;

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.capability-ticket-template-img {
  padding-top: 64px;
  box-sizing: border-box;
}

.capability-assigne-img {
  padding: 13vh 12vw 0px 0px;
  box-sizing: border-box;
}

.capability-teams-img {
  padding: 64px 81px 64px 31px;
  box-sizing: border-box;
}

@media screen and (max-width:1000px) {
  .capabilities-page {
    padding: 24px;
  }

  .capabilities {
    padding: 24px;
    width: 100%;
  }

  .capabilities-head {
    font-size: 12px;
  }

  .capabilities-desc {
    font-size: 24px;
    line-height: 36px;
  }


  .capability-ticket-template,
  .capability-assigne,
  .capability-teams,
  .capability-org {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 32px;
    justify-content: space-between;
    /* height: 584px; */
  }

  .capability-ticket-template-content,
  .capability-assigne-content,
  .capability-teams-content,
  .capability-org-content {
    padding: 16px 28px 40px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 390px;
  }

  .capability-ticket-template-img {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0px;
    height: 520px;
  }

  .capability-ticket-template-img img {
    height: 100%;
    width: 90%;
  }

  .capability-assigne-img {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    height: 65%;
  }

  .capability-assigne-img img {
    width: 60%;
  }

  .capability-teams-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 429px;
    padding: 0px;
  }

  .capability-org-img {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 65%;
    /* padding: 0px; */
  }

  .capability-org-img img {
    width: 400px;
    height: 300px;
  }

  .capability-teams-img img {
    width: 80%;
    height: 80%;
  }

  .capability-ticket-template-head,
  .capability-assigne-head,
  .capability-teams-head,.capability-org-head {
    font-size: 24px;
    line-height: 32px;
    /* padding: 24px; */
    width: 100%;
    box-sizing: border-box;
  }

  .capability-assigne-desc,
  .capability-teams-desc,
  .capability-ticket-template-desc,.capability-org-desc {
    font-size: 14px;
    padding: 0px;
  }
}

@media screen and (max-width:750px) {
  .capability-teams-img{
    
    width: 100%;
    height: 350px;
  }
  .capability-ticket-template-img {
    height: 720px;
  }
  .capability-org-img{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    padding: 0px;
  }
  .capability-assigne-img img {
    width: 80%;
  }
  .capability-org-img img{
    width: 80%;
    height: 170px;
  }
}

@media screen and (max-width:500px) {

  /* .capability-teams-img{
    height: 30%;
  } */
  .capability-ticket-template,
  .capability-assigne,
  .capability-teams,.capability-org {
    height: 450px;
  }
  

}