/* .part-attribute-page {
    padding-top: 300px;
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    box-sizing: border-box;

} */
.part-attribute-close {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.part-attribute-popup {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 19, 37, 0.5);
    z-index: 2;
    width: 100%;
    height: 100vh;
}

.part-attribute-div {
    margin-top: 80px;
    /* margin-bottom: 50px; */
    display: flex;
    width: 100%;
    /* width: 80%; */
    padding: 24px;
    height: 85vh;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #EDF2F7;
    background: #FFF;
    overflow: auto;
    /* Popup */
    /* box-shadow: 0px 12px 32px 0px rgba(6, 28, 61, 0.08); */
}
.attribute-page-back{
    display: flex;
    align-items: center;
    gap: 20px;
}
.attribute-page-back img{
    cursor: pointer;
}
.part-attribute-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-bottom: 24px;
}

.part-attri-title {
    color: #001325;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.3px;

}

.part-attr-error {
    color: #FF3B2F;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.part-attribute-save-btn {
    display: flex;
    padding-top: 24px;

    width: 100%;
}

.part-attribute-save-btn button {
    border-radius: 5px;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    padding: 10px 20px;
    border: none;
}

.attribute-save-active {
    background: #610BEE;
}
.mbom-attribute-save-active {
    background: #610BEE;
    border-radius: 5px;

    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    padding: 10px 20px;
    border: none;
}
.attribute-save-inactive {
    background: #CABDFC;
}

.part-attribute-options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 600vh;
    overflow: auto;
    width: 100%;
    gap: 16px;
    box-sizing: border-box;
}

.part-attri-option-div,
.part-attri-transition {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding: 12px;
    border-radius: 4px;
    gap: 8px;
    border-radius: 4px;
    background: #F6F6F6;
}

.part-attri-transition {
    padding: 0px;
}

.part-attribute-options-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.part-attribute-options-title span {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}

.part-attri-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
    padding: 10px 16px 10px 0px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #EDF2F7;
    background: #FFF;
}

.part-attri-input input {
    width: 100%;
    border: none;
    outline: none;
    color: #080808;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding-left: 10px;

}

.part-attri-input img {
    cursor: pointer;
}

.drop-delete-attribute {
    display: flex;
    align-items: center;
    gap: 20px;
}

.part-attri-title-edit {
    display: flex;
    align-items: center;
    gap: 25px;
    padding-right: 25px;
}

.part-attri-title-edit img {
    display: none;
}

.part-attri-title-edit:hover img {
    display: flex;
}
.part-title-edit-input{
    display: flex;
    align-items: center;
    gap: 10px;
}
.part-title-edit-input input{
    border-radius: 25px;
    border: none;
    padding: 5px;
    outline: none;
}
.part-title-edit-input button{
    padding: 5px 10px;
}
.drop-delete-attribute img {
    cursor: pointer;
}

.attribute-add-attribute {
    border: 1px solid red;
    color: red;
    background: white;
    border-radius: 4px;
}

.part-attri-transition button {
    background: inherit;
    border: none;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 4px;
    color: #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    padding: 0px;
}