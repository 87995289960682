.closing-notif {
    cursor: pointer;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .closing-notif:hover {
    background: rgb(217, 217, 217);
  }