.member-settings-page-div{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
 border-radius: 25px;
 width: 300px;
 /* height: 500px; */
 padding: 20px;
 background: white;
 position: absolute;
 z-index: 10;

 right: 10px;
}
.cross-icon{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
}
.top-nav-popup-memberPhoto{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /* margin-right: 10px; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #610bee;
    color: white;
    font-weight: 500;
    font-size: 32px;
    text-align: center;
}
.cross-hover-effect{
    padding: 5px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.cross-hover-effect:hover{
    cursor: pointer;
background: rgb(233, 233, 233);
}
.member-setting-details{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
    
}
.member-name-span{
    color: black;
    font-weight: 600;
}
.member-settings-btn{
display: flex;
align-items: center;
justify-content: center;
border: 1.5px solid white;
border-radius: 50px;
padding: 15px;
}
.member-settings-btn:hover{
border: 1.5px solid #610BEE;
}