.prod-sche-page {
    margin-top: 80px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    width: 1000%;
    box-sizing: border-box;
    max-height: 88vh;
    overflow-y: auto;
    

}

.prod-sche-top-nav {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
}

.prod-schedule-back-nav {
    color: #001325;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: -0.288px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.prod-schedule-filters {
    display: flex;
    align-items: center;
    gap: 16px;

}

.prod-schedule-filters-btns button {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    background: white;
    border: 1px solid #EDF2F7;
    padding: 5px 14px;
}

.prod-schedule-filters-btn1 {
    border-radius: 5px 0px 0px 5px;
}

.prod-schedule-filters-btn3 {
    border-radius: 0px 5px 5px 0px;
}

.prod-schedule-filters-btn1.selected,
.prod-schedule-filters-btn2.selected,
.prod-schedule-filters-btn3.selected {
    border-color: #610bee;
    color: #610bee;
}

.production-schedule-table {
    display: flex;
    width: 100%;
}

.production-schedule-table-view {
    text-align: left;
    padding: 0px;
}

.production-schedule-table-view td {
   
    padding: 0px;
}

.production-table-row-head {
    width: 600px;
    padding: 16px;
    box-sizing: border-box;
}

.production-table-row-data {
    width: 150px;
    padding: 16px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.14px;
}
.production-table-horizon{
    /* width: 100%;
    height: 2px; */
    border-left: 1px solid #edf2f7;
    border-bottom: 2px solid #CCD1E0;

}
.production-date-filters{
    display: flex;
    align-items: center;
    gap: 8px;
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}
.production-date-filters input{
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #edf2f7;
    border-radius: 4px;
}
.production-schedule-head:hover {
    background: white;
}
.edit-prod-table-cell{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.edit-prod-table-cell input{
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #edf2f7;
    padding: 5px;
    border-radius: 4px;
}


.create-production-plan {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    gap: 28px;
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.11);
}

.production-plan-inputs {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.production-plan-inputs input[type="text"],
.production-plan-inputs input[type="date"],
.production-plan-inputs input[type="number"] {
    padding: 10px 16px;
    border: 1px solid #edf2f7;
    border-radius: 4px;
    outline: none;
}

.production-plan-bottom {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.create-production-plan-heading {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.192px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.production-edit {
    background: inherit;
    border: none;
    color: #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}
.production-schedule-table-view tr:hover{
    background: white;
}

/* strike off styling  */
.text-striking-cross {
    position: relative;
    display: inline-block;
   
  }
  
  .text-striking-cross::before {
    content: '';
    position: absolute;
    width: 150%;
    height: 1.5px;
    background-color: black; /* Color of the cross */
    top: 40%;
    left: -2px;
    transform: rotate(-45deg); /* First diagonal */
  }
  
  /* .text-striking-cross::after {
    transform: rotate(-45deg); 
  } */
  