.create-grn-popup {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 19, 37, 0.5);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
}

.create-grn-div {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 85vh;
    padding: 24px;
    border-radius: 8px;
    box-sizing: border-box;
    background: white;
}

.create-grn-table {
    width: 100%;
    height: 55vh;
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.create-grn-table table {
    width: 100%;
}

.create-grn-table thead {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
    text-align: left;
}

.create-grn-table tbody {
    color: #001325;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.create-grn-table td {
    text-align: left;
}

.create-grn-table-input {
    width: 100px;
    height: 48px;
    padding: 12px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #EDF2F7;
    background: inherit;
}

.create-grn-table-remarks {
    width: 177px;
    height: 48px;
    padding: 12px 42px 12px 18px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #EDF2F7;
    background: inherit;
}

.modal-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    border-top: 1px solid #ddd;
}

.btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.go-back {
    border-radius: 5px;
    border: 1.5px solid #818181;
    color:  #818181;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;
}

.partial-receive {
    border-radius: 5px;
    border: 1px solid #610BEE;
    background-color: #ffffff;

    color: #610BEE;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;

}

.close-po {
    background-color: #610bee;
    color: #fff;
}

.btn:hover {
    opacity: 0.8;
}




.transfer-table{
    width: 100%;
    border: 1px solid red;
    text-align: left;
}