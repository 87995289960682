.welcome-page{
    display: flex;
    width: 100%;
    justify-content: space-between;
    height: 100vh;
}
.welcome-title {
    display: flex;
    align-items: center;
    gap: 16px;
}

.welcome-title img {
    width: 40px;
    height: 40px;
}

.welcome-title span {
    color: #001325;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.48px;
}

.welcome-desc {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.welcome-desc span {
    color: #5e6b77;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
}

.nxt-btn-wel {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.nxt-btn-wel button {
    width: 31.7vw;
    height: 5.6vh;
    padding: 10px 20px;
    background: #610BEE;
    border: none;
    border-radius: 4px;
    color:  #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.14px;

}
.left-container1{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}